import React from 'react';

import { leaveBreadcrumb } from 'Utils';

import About from 'Routing/About';
import ExportView from 'Routing/ExportView';

import Header from 'Components/Header';
import Footer from 'Components/Footer';
import Intro from 'Components/Intro';
import Map from 'Components/Map';

function Routing() {
    leaveBreadcrumb('Routing initiated.');

    return (
        <>
            <Header />
            <About />
            <Intro />
            <ExportView />
            <Map />
            <Footer />
        </>
    );
}

export default Routing;
