const actionStatus = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    REQUEST: 'REQUEST',
    PENDING: 'PENDING',
    SEARCHING: 'SEARCHING',
    WAITING: 'WAITING',
};

export default actionStatus;
