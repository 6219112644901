import newFactoryData from 'Store/Constants/newData';
import countries from 'Store/Constants/newBorders';

import percentage from 'Utils/percentage';

function buildCountryList(factories = []) {
  return factories.reduce((allCountries, factory) => {
    const resAllCountries = allCountries;
    const countryGeoJson = countries?.[factory?.geoData?.A3];
    if (factory?.geoData?.A3 && countryGeoJson) {
      resAllCountries[factory?.geoData?.A3] = countryGeoJson;
    }

    return resAllCountries;
  }, {});
}

function useData(props = {}) {
  const {
    data = [],
    factoriesToRender = {},
    countryData = [],
  } = props;

  const filteredData = data;

  const dataLength = filteredData.length;

  const dataLengthPercentage = Math.floor(
    (filteredData.length / newFactoryData.length) * 100,
  );

  const allTier1Factories = newFactoryData?.filter(
    (currentFactoryData) =>
      ['Tier 1/ATL', 'Tier 2/ATL'].includes(
        currentFactoryData?.factory_tier,
      ),
  );

  const tier1Factories = newFactoryData?.filter(
    (currentFactoryData) =>
      ['Tier 1/ATL', 'Tier 2/ATL'].includes(
        currentFactoryData?.factory_tier,
      ) && factoriesToRender?.[currentFactoryData?.contractor_id],
  );
  const materialsFactories = newFactoryData?.filter(
    (currentFactoryData) =>
      ['Tier 2/BTL'].includes(currentFactoryData?.factory_tier) &&
      factoriesToRender?.[currentFactoryData?.contractor_id],
  );

  const allMaterialsFactories = newFactoryData?.filter(
    (currentFactoryData) =>
      ['Tier 2/BTL'].includes(currentFactoryData?.factory_tier),
  );
  const materialsFactoriesCountryCount = Object.keys(
    materialsFactories?.reduce((allCountries, currentFactoryData) => {
      return {
        ...allCountries,
        [currentFactoryData?.geoData?.A3]: true,
      };
    }, {}),
  ).reduce((allCountries) => {
    return allCountries + 1;
  }, 0);
  const tier1FactoriesCountryCount = Object.keys(
    tier1Factories?.reduce((allCountries, currentFactoryData) => {
      return {
        ...allCountries,
        [currentFactoryData?.geoData?.A3]: true,
      };
    }, {}),
  ).reduce((allCountries) => {
    return allCountries + 1;
  }, 0);
  const workers = tier1Factories?.reduce(
    (allWorkers, currentFactoryData) => {
      return Number(allWorkers) + Number(currentFactoryData?.workers);
    },
    0,
  );

  const workersLabel = workers
    ?.toString?.()
    ?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',');

  const allCountryMetrics = countryData.reduce(
    (allFData, factoryData) => {
      const newAllAges =
        Number(factoryData?.average_worker_age || 0) +
        (allFData?.allAges || 0);

      const newAllWorkers =
        Number(factoryData?.workers || 0) +
        (allFData?.allWorkers || 0);

      const workers_female_str = String(factoryData?.workers_female)?.replace('%', '');

      const newAllFemaleWorkers =
        (Number(factoryData?.workers || 0) / 100) *
        Number(workers_female_str);

      const workers_migrant_str = String(factoryData?.workers_migrant)?.replace('%', '');

      const newAllMigrantWorkers =
        (Number(factoryData?.workers || 0) / 100) *
        Number(workers_migrant_str);

      return {
        ...allFData,
        allAges: newAllAges,
        allWorkers: newAllWorkers,
        allFemaleWorkers:
          Number(newAllFemaleWorkers) +
          Number(allFData?.allFemaleWorkers || 0),
        allMigrantWorkers:
          Number(newAllMigrantWorkers) +
          Number(allFData?.allMigrantWorkers || 0),
      };
    },
    {},
  );

  const countryMetrics = data.reduce((allFData, factoryData) => {
    const newAllAges =
      Number(factoryData?.average_worker_age || 0) +
      (allFData?.allAges || 0);

    const newAllWorkers =
      Number(factoryData?.workers || 0) + (allFData?.allWorkers || 0);

    const workers_female_str = String(factoryData?.workers_female).replace('%', '');

    const newAllFemaleWorkers =
      (Number(factoryData?.workers || 0) / 100) *
      Number(workers_female_str);

    const workers_migrant_str = String(factoryData?.workers_migrant).replace('%', '');

    const newAllMigrantWorkers =
      (Number(factoryData?.workers || 0) / 100) *
      Number(workers_migrant_str);

    return {
      ...allFData,
      allAges: newAllAges,
      allWorkers: newAllWorkers,
      allFemaleWorkers:
        Number(newAllFemaleWorkers) +
        Number(allFData?.allFemaleWorkers || 0),
      allMigrantWorkers:
        Number(newAllMigrantWorkers) +
        Number(allFData?.allMigrantWorkers || 0),
    };
  }, {});

  const worldMetrics = {
    allWorkers:
      allTier1Factories?.reduce((allWorkers, currentFactoryData) => {
        return (
          Number(allWorkers) + Number(currentFactoryData?.workers)
        );
      }, 0) || 0,
    allTier1Factories,
  };

  if (countryData.length) {
    allCountryMetrics.averageAge = Math.floor(
      allCountryMetrics.allAges / countryData.length,
    );

    const allCountryWorkers = countryData?.reduce(
      (allWorkers, currentFactoryData) => {
        return (
          Number(allWorkers) + Number(currentFactoryData?.workers)
        );
      },
      0,
    );

    allCountryMetrics.workersPercentage = percentage(
      allCountryMetrics?.allWorkers,
      allCountryWorkers,
    );

    allCountryMetrics.workersToGlobalPercentage = percentage(
      allCountryWorkers,
      worldMetrics.allWorkers,
    );

    allCountryMetrics.workersToGlobalPercentageLabel =
      allCountryMetrics?.workersToGlobalPercentage + '%';

    allCountryMetrics.allWorkersLabel = allCountryMetrics.allWorkers
      ?.toString?.()
      ?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',');

    allCountryMetrics.workersPercentageLabel =
      allCountryMetrics?.workersPercentage + '%';

    allCountryMetrics.allFemaleWorkersPercentage = Number(
      Number(
        (Number(allCountryMetrics?.allFemaleWorkers || 0) /
          Number(allCountryMetrics?.allWorkers || 0)) *
        100,
      ).toFixed(1),
    );

    allCountryMetrics.allFemaleWorkersPercentageLabel =
      allCountryMetrics?.allFemaleWorkersPercentage + '%';

    allCountryMetrics.allMigrantWorkersPercentage = Number(
      Number(
        (Number(allCountryMetrics?.allMigrantWorkers || 0) /
          Number(allCountryMetrics?.allWorkers || 0)) *
        100,
      ).toFixed(1),
    );

    allCountryMetrics.allMigrantWorkersPercentageLabel =
      allCountryMetrics?.allMigrantWorkersPercentage + '%';

    allCountryMetrics.dataLength = countryData.length;

    allCountryMetrics.dataPercentage = percentage(
      newFactoryData.length,
      countryData.length,
    );
  }

  if (data.length) {
    countryMetrics.averageAge = Math.floor(
      countryMetrics.allAges / data.length,
    );

    countryMetrics.workersPercentage = Math.floor(
      (countryMetrics?.allWorkers / workers) * 100,
    );

    countryMetrics.allWorkersLabel = countryMetrics.allWorkers
      ?.toString?.()
      ?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',');

    countryMetrics.workersPercentageLabel =
      countryMetrics?.workersPercentage + '%';

    countryMetrics.allFemaleWorkersPercentage = Number(
      Number(
        (Number(countryMetrics?.allFemaleWorkers || 0) /
          Number(countryMetrics?.allWorkers || 0)) *
        100,
      ).toFixed(1),
    );

    countryMetrics.allFemaleWorkersPercentageLabel =
      countryMetrics?.allFemaleWorkersPercentage + '%';

    countryMetrics.allMigrantWorkersPercentage = Number(
      Number(
        (Number(countryMetrics?.allMigrantWorkers || 0) /
          Number(countryMetrics?.allWorkers || 0)) *
        100,
      ).toFixed(1),
    );

    countryMetrics.allMigrantWorkersPercentageLabel =
      countryMetrics?.allMigrantWorkersPercentage + '%';
  }

  const tier1Metrics = {
    workersToGlobalPercentage:
      percentage(workers, worldMetrics.allWorkers) || 0,
    factoriesToGlobalPercentage: percentage(
      tier1Factories.length,
      allTier1Factories.length,
    ),
  };

  tier1Metrics.countryPercentage = percentage(
    tier1FactoriesCountryCount,
    Object.keys(buildCountryList(allTier1Factories)).length,
  );

  tier1Metrics.countryPercentageLabel =
    tier1Metrics.countryPercentage + '%';

  tier1Metrics.workersToGlobalPercentageLabel =
    tier1Metrics.workersToGlobalPercentage + '%';

  tier1Metrics.factoriesToGlobalPercentageLabel =
    tier1Metrics.factoriesToGlobalPercentage + '%';

  for (let i = 0; i < tier1Factories.length; i += 1) {
    const tier1FactoryData = tier1Factories[i];
    tier1Metrics[tier1FactoryData.product_type] = tier1Metrics?.[
      tier1FactoryData.product_type
    ]
      ? tier1Metrics[tier1FactoryData.product_type] + 1
      : 1;
  }

  const materialsMetrics = {
    factoriesLength: materialsFactories.length,
    factoriesPercentage: percentage(
      materialsFactories.length,
      allMaterialsFactories.length,
    ),
  };

  materialsMetrics.countryPercentage = percentage(
    materialsFactoriesCountryCount,
    Object.keys(buildCountryList(allMaterialsFactories)).length,
  );

  materialsMetrics.countryPercentageLabel =
    materialsMetrics.countryPercentage + '%';

  materialsMetrics.factoriesPercentageLabel =
    materialsMetrics.factoriesPercentage + '%';

  return {
    country: {
      ...countryMetrics,
      workers: countryMetrics?.allWorkers,
      workersLabel: countryMetrics?.allWorkersLabel,
      workersPercentage: countryMetrics?.workersPercentage,
      workersFemalePercentageLabel:
        countryMetrics?.allFemaleWorkersPercentageLabel,
      averageAge: countryMetrics?.averageAge,
      filteredData,
      dataLength,
      dataLengthPercentage,
    },
    currentCountry: {
      ...allCountryMetrics,
      workers: allCountryMetrics?.allWorkers,
      workersLabel: allCountryMetrics?.allWorkersLabel,
      workersPercentage: allCountryMetrics?.workersPercentage,
      workersFemalePercentageLabel:
        allCountryMetrics?.allFemaleWorkersPercentageLabel,
      averageAge: allCountryMetrics?.averageAge,
    },
    tier1: {
      ...tier1Metrics,
      factories: tier1Factories,
      allTier1Factories,
      workers,
      workersLabel,
      countryLength: tier1FactoriesCountryCount,
    },
    materials: {
      ...materialsMetrics,
      factories: materialsFactories,
      countryLength: materialsFactoriesCountryCount,
    },
    world: {
      ...worldMetrics,
    },
  };
}

export default useData;
