function getZoomFromArea(area) {
    const { innerWidth: w, innerHeight: h } = window;

    // egypt: 985037235744.7864

    // brazi: 8515866313830.959
    // brath: 9810905891.51032
    // br333: 4292271327.5357656
    // moldo: 33190073661.803978

    const zoomThresholds = {
        4.0: 4292271327.5357656,
        5.5: 496490542.21007377,
        6.0: 16728867.773086682,
        7.0: 1672886.773086682,
    };

    const zoomTKeys = Object.keys(zoomThresholds);

    let newZoom;

    const areaThres = area / (h + w);

    function getThresholdDiff(t) {
        return Math.abs(areaThres / t) * 6200;
    }

    for (let i = 0; i < zoomTKeys.length; i += 1) {
        const zoom = zoomTKeys[i];
        const threshold = zoomThresholds[zoom];
        const thresholdDiff = getThresholdDiff(threshold);
        if (thresholdDiff > 5000) {
            if (
                newZoom &&
        getThresholdDiff(zoomThresholds[newZoom]) < thresholdDiff
            ) {
                // na
            } else {
                newZoom = Number(zoom);
            }
        }
    }

    return newZoom || 5;
}

export default getZoomFromArea;
