import useData from 'Hooks/useData';
import useFilters from 'Hooks/useFilters';

import { useSelector } from 'react-redux';

function useMetrics(props = {}) {
    const { factories = [], region = 'WORLD' } = props;

    const { countryData } = useSelector((state) => {
        const countryIdRaw = state?.exportView?.countryId;
        const countryToDataRaw = state?.countryToData;

        return {
            countryId: countryIdRaw,
            countryToData: countryToDataRaw,
            countryData: countryToDataRaw?.[countryIdRaw]?.data,
        };
    });
    const { countriesToRender, factoriesToRender } = useFilters();
    const {
        country: countryMetrics = {},
        tier1 = {},
        materials = {},
        currentCountry: currentCountryMetrics = {},
        world: worldMetrics = {},
    } = useData({
        data: factories.filter((someFactoryInCountry) => {
            if (!factoriesToRender?.[someFactoryInCountry?.contractor_id]) {
                return false;
            }
            return true;
        }),
        countryData,
        countriesToRender,
        factoriesToRender,
    });

    return {
        countryMetrics,
        tier1,
        materials,
        countryData,
        currentCountryMetrics,
        worldMetrics,
    };
}

export default useMetrics;
