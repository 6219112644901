import { compose, applyMiddleware, createStore } from 'redux';
import rootReducer from './Reducers';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Sagas';
import envConfig from 'Assets/config/env';

const sagaMiddleware = createSagaMiddleware();

function configureStore() {
    const devOn = envConfig?.id === 'dev';
    const composeEnhancers = compose;
    const middleware = [sagaMiddleware];

    if (devOn) {
        middleware.unshift(logger);
    }

    const middlewares = devOn
        ? [logger, sagaMiddleware]
        : [sagaMiddleware];

    let store = createStore(
        rootReducer,
        {},
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    sagaMiddleware.run(rootSaga);

    return [store];
}

export default configureStore;
