import React, {
    useRef,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';

import useWindowDimensions from 'Hooks/useWindowDimensions';
import useFilters from 'Hooks/useFilters';
import { useSelector } from 'react-redux';

import {
    GoogleMap,
    useJsApiLoader,
    MarkerClusterer,
    Polygon,
    OverlayView,
    Marker,
} from '@react-google-maps/api';

import Region from 'Components/Region';
import RenderPolygons from './RenderPolygons';

import countryBorders from 'Store/Constants/newBorders';
import zoomLevels from 'Store/Constants/zoomLevels';
import useActions from 'Hooks/useActions';

import getZoomFromArea from 'Utils/getZoomFromArea';
import makePolygonList from 'Utils/makePolygonList';

const centerInit = {
    lat: -5.298002,
    lng: 19.508116,
};

// maps.Polygon({paths: decodedPath, fillColor: '#54cafe', strokeColor: '#b5e8ff', strokeWeight: .75, fillOpacity: .75});

function MyMapComponent({
    height,
    width,
    config,
    zoom = zoomLevels.GLOBAL,
    countryToData = {},
    setZoom,
    mapRef,
}) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config?.API_KEY,
        disableDefaultUI: true,
    });

    const actions = useActions();

    const [map, setMap] = useState(null);
    const [repaint, setRepaint] = useState(true);
    const [hoveredPoly, setHoveredPoly] = useState('');
    const [selectedPoly, setSelectedPoly] = useState('');
    const [selectedFactory, setSelectedFactory] = useState('');
    const centerSelectedPoly = useRef({});
    const zoomType = useRef('global');
    const tapFromCluster = useRef(false);

    const [center, setCenter] = useState(centerInit);

    function setSelectedFactoryCluster(facId) {
        tapFromCluster.current = facId;
        setSelectedFactory(facId);
    }

    const {
        factoriesToRender = {},
        countriesToRender: countries = {},
    } = useFilters();

    const containerStyle = {
        width,
        height,
    };

    const onLoad = React.useCallback(function callback(mapArg) {
    // const bounds = new window.google.maps.LatLngBounds();
    // mapArg.fitBounds(bounds);
    // const init = new window.google.maps.LatLng(-5.298002, 19.508116);
    // const bounds = new window.google.maps.LatLngBounds(init);
    // mapArg.fitBounds(bounds);
        setMap(mapArg);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const hoverPolyOptions = {
        fillColor: '#fab368',
        strokeColor: '#d97c1a',
        strokeWeight: 0.75,
        fillOpacity: 0.55,
    };

    const selectedPolyOptions = {
        fillColor: '#fab368',
        strokeColor: '#d97c1a',
        strokeWeight: 2,
        fillOpacity: 0.55,
    };

    function onGlobeMouseOver() {
        setHoveredPoly('');
    }

    function onCountryBreadcrumTap() {
        if (selectedPoly && selectedFactory) {
            zoomType.current = 'country';
            setSelectedFactory('');
            setZoom(zoomLevels.COUNTRY);

            const countryCenter =
        countries?.[selectedPoly]?.center?.geometry?.coordinates;

            setCenter({
                lat: countryCenter?.[1],
                lng: countryCenter?.[0],
            });

            actions?.SELECT_COUNTRY?.({
                countryId: selectedPoly,
                countryName: countries?.[selectedPoly]?.properties?.ADMIN,
            });
        }
    }

    function onGlobeBreadcrumTap() {
        if (selectedPoly) {
            zoomType.current = 'global';
            setSelectedFactory('');
            setSelectedPoly('');
            setHoveredPoly('');
            setCenter(centerInit);
            setZoom(zoomLevels.GLOBAL);

            actions?.SELECT_COUNTRY?.({
                countryId: '',
                countryName: '',
            });
        }
    }

    useEffect(() => {
        if (selectedPoly) {
            const selectedCountry =
        countryToData?.[selectedPoly.toUpperCase()];

            const lat =
        countries?.[selectedPoly]?.center?.geometry?.coordinates[1];
            const lng =
        countries?.[selectedPoly]?.center?.geometry?.coordinates[0];

            centerSelectedPoly.current = {
                lat,
                lng,
            };

            if (selectedCountry) {
                console.log('selectedCountry zoom/center changing');
                setCenter({
                    lat: Number(lat),
                    lng: Number(lng),
                });

                const newZoom = getZoomFromArea(
                    countries?.[selectedPoly]?.area,
                );
                setZoom(newZoom);
            }
        }
        console.log({ selectedPoly, mapRef });
    }, [selectedPoly]);

    useEffect(() => {
        if (selectedFactory) {
            const factoryData = countryToData?.[selectedPoly]?.data?.filter(
                (availableCountryFactories) =>
                    availableCountryFactories?.contractor_id ===
          selectedFactory,
            )?.[0];

            if (factoryData) {
                const newFactoryCenter = {
                    lat: Number(factoryData?.lat),
                    lng: Number(factoryData?.lon),
                };
                if (tapFromCluster.current) {
                    tapFromCluster.current = '';
                    console.log({ newFactoryCenter });
                } else {
                    setCenter(newFactoryCenter);

                    if (!isNaN(zoomType.current)) {
                        // na
                    } else {
                        setZoom(zoomLevels.FACTORY);
                    }
                }
            }
        }
    }, [selectedFactory]);

    return isLoaded ? (
        <GoogleMap
            ref={mapRef}
            onMouseMove={onGlobeMouseOver}
            options={{
                disableDefaultUI: true,
                backgroundColor: '#ffffff',
                mapTypeId: 'roadmap',
                styles: config?.styles,
            }}
            mapContainerStyle={containerStyle}
            {...{ zoom, onLoad, onUnmount, center }}
        >
            {selectedPoly ? (
                <MarkerClusterer
                    styles={[
                        {
                            color: '#ccc',
                            height: 60,
                            url: '/img/css/cluster/cluster.png',
                            width: 60,
                        },
                    ]}
                    maxZoom={20}
                    minZoom={4}
                    ignoreHidden
                    averageCenter
                >
                    {(clusterer) =>
                        countryToData[selectedPoly].data
                            .filter((someFactoryInCountry) => {
                                if (
                                    !factoriesToRender?.[
                                        someFactoryInCountry?.contractor_id
                                    ]
                                ) {
                                    return false;
                                }
                                return true;
                            })
                            .map((location, locationIndex) => {
                                function handleLocationClick() {
                                    const currentClusterZoom =
                    clusterer?.activeMap?.zoom;
                                    console.log({
                                        clusterer,
                                        location,
                                        currentClusterZoom,
                                    });

                                    //zoomType.current = currentClusterZoom;
                                    setSelectedFactoryCluster(location?.contractor_id);
                                    actions?.SELECT_FACTORY?.({
                                        factoryId: location?.contractor_id,
                                    });
                                }
                                const markerLocation = {
                                    lat: location.geoData.latitude,
                                    lng: location.geoData.longitude,
                                };
                                return (
                                    <Marker
                                        key={
                                            location?.contractor_id ||
                      locationIndex.toString()
                                        }
                                        icon="/img/css/cluster/cluster.png"
                                        maxZoom={10}
                                        minZoom={4}
                                        onClick={handleLocationClick}
                                        ignoreHidden
                                        position={markerLocation}
                                        clusterer={clusterer}
                                    />
                                );
                            })
                    }
                </MarkerClusterer>
            ) : null}
            {selectedPoly ? (
                <OverlayView
                    position={centerSelectedPoly.current}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div style={{ top: -140, left: -140 }}>
                        <h1 style={{ color: 'white', fontSize: 37 }}>
                            {countries?.[selectedPoly]?.properties?.[
                                'woe-label'
                            ]?.toUpperCase?.()}
                        </h1>
                    </div>
                </OverlayView>
            ) : null}

            {makePolygonList(countries, selectedPoly, hoveredPoly).map(
                (c) => {
                    const isCountrySelected = selectedPoly === c.countryId;

                    function handleClickPolygon() {
                        if (!isCountrySelected) {
                            zoomType.current = isCountrySelected
                                ? 'global'
                                : 'country';

                            setCenter(
                                isCountrySelected
                                    ? centerInit
                                    : {
                                        lat: c.center?.[1],
                                        lng: c.center?.[0],
                                    },
                            );

                            setSelectedPoly(c.countryId);

                            actions?.SELECT_COUNTRY?.({
                                countryId: c.countryId,
                                countryName:
                  countries?.[c.countryId]?.properties?.ADMIN,
                            });
                        }
                    }

                    return c?.isMulti ? (
                        (c?.paths || []).map((polyPaths, polyPathsIndex) => {
                            return (
                                <Polygon
                                    key={polyPathsIndex}
                                    paths={polyPaths}
                                    options={c?.polyOptionsToRender}
                                    onMouseOver={() => {
                                        setHoveredPoly(c.countryId);
                                    }}
                                    onClick={handleClickPolygon}
                                />
                            );
                        })
                    ) : (
                        <Polygon
                            paths={c?.paths}
                            options={c?.polyOptionsToRender}
                            onMouseOver={() => {
                                setHoveredPoly(c.countryId);
                            }}
                            onClick={handleClickPolygon}
                        />
                    );
                },
            )}

            {/* <RenderPolygons
        countries={countries}
        countryCount={Object.keys(countries).length}
        selectedPoly={selectedPoly}
        hoveredPoly={hoveredPoly}
        setHoveredPoly={setHoveredPoly}
        {...{
          selectedPolyOptions,
          zoomType,
          setCenter,
          zoomLevels,
          setSelectedPoly,
          setZoom,
          centerInit,
          hoverPolyOptions,
        }}
      /> */}

            <Region
                country={selectedPoly}
                factory={selectedFactory}
                setFactory={setSelectedFactory}
                setCountry={setSelectedPoly}
                onGlobe={onGlobeBreadcrumTap}
                onCountry={onCountryBreadcrumTap}
                countryLabel={countries?.[selectedPoly]?.properties?.[
                    'woe-label'
                ]?.toUpperCase?.()}
                countryObject={countries?.[selectedPoly]}
                factoryObject={
                    countryToData?.[selectedPoly]?.data?.filter(
                        (someCountry) =>
                            someCountry?.contractor_id === selectedFactory,
                    )?.[0]
                }
            />
        </GoogleMap>
    ) : (
        <></>
    );
}

const Map = () => {
    const { height, width } = useWindowDimensions();
    const { googleConfig, countryToData } = useSelector((state) => {
        return {
            data: state?.data,
            googleConfig: state?.config?.GOOGLE,
            countryToData: state?.countryToData,
        };
    });

    const mapRef = useRef(null);
    const mapWrapperRef = useRef(null);

    const [zoom, setZoom] = useState(zoomLevels.GLOBAL);
    const lastZoom = useRef(zoom);
    const [mapOptions, setMapOptions] = useState(undefined);

    useEffect(() => {
        if (mapOptions && mapRef.current?.chart) {
            if (zoom === 1 || zoom > 1) {
                mapRef.current.chart.zoomOut();
            } else {
                mapRef.current.chart.mapZoom(zoom);
            }

            lastZoom.current = zoom;
        }
    }, [zoom, mapOptions]);

    return (
        <>
            <aside className="zoom-controls">
                <a
                    href="#"
                    onClick={() => {
                        const newZoom = zoom - 1;
                        if (newZoom <= zoomLevels.GLOBAL) {
                            setZoom(zoomLevels.GLOBAL);
                        } else {
                            setZoom(newZoom);
                        }
                    }}
                    className="out"
                >
          Zoom Out
                </a>
                <a
                    href="#"
                    onClick={() => {
                        const newZoom = zoom + 1;
                        setZoom(newZoom);
                    }}
                    className="in"
                >
          Zoom In
                </a>
            </aside>
            <MyMapComponent
                mapRef={mapRef}
                isMarkerShown={false}
                //googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleConfig?.API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                //googleMapURL={`http://maps.googleapis.com/maps/api/staticmap?center='-5.298002,19.508116'&zoom=5&size=640x640&sensor=true&markers=color:blue`}
                height={height - 47}
                config={googleConfig}
                {...{
                    zoom,
                    width,
                    setZoom,
                    countryToData,
                }}
            />
        </>
    );
};

export default Map;
