import React, { useEffect, useState, useRef } from 'react';
import { Polygon } from '@react-google-maps/api';
import useActions from 'Hooks/useActions';
import countriesJson from 'Store/Constants/newBorders';

import { useSelector } from 'react-redux';
import useFilters from 'Hooks/useFilters';

function RenderPoly({
    poly,
    countryId,
    isSelectedPoly,
    isHoveredPoly,
    country,
    actions,
    selectedPoly,
    countriesWithPoly,
    setHoveredPoly,
    selectedPolyOptions,
    zoomType,
    setCenter,
    zoomLevels,
    setSelectedPoly,
    setZoom,
    centerInit,
    hoverPolyOptions,
    hide = false,
    area,
}) {
    let polyKey = '';

    const { countriesToRender } = useFilters();
    const countryIdSelected = useSelector(
        (state) => state?.exportView?.countryId,
    );
    const [render, setRender] = useState(!hide);

    useEffect(() => {
        console.log(countryId, render);
    }, [render]);

    if (!countriesWithPoly.current?.[countryId]) {
        countriesWithPoly.current[countryId] = {
            id: countryId,
            polyCount: 1,
        };
        polyKey = countryId;
    } else {
        const nextCountryPolyCount =
      countriesWithPoly.current[countryId].polyCount + 1;
        polyKey = `${countryId}-${nextCountryPolyCount}`;
        countriesWithPoly.current[polyKey] = countryId;
        countriesWithPoly.current[
            countryId
        ].polyCount = nextCountryPolyCount;
    }
    const isCountrySelected = selectedPoly === countryId;

    // if (selectedPoly && !isCountrySelected) {
    //   return null;
    // }

    const paths = poly?.map?.((coords) => {
        const lat = parseFloat(coords?.[1]);
        const lng = parseFloat(coords?.[0]);
        return { lat, lng };
    });

    function onCountryHover() {
        setHoveredPoly(countryId);
    }

    const polyOptionsToRender = {
        fillColor: '#f7701e',
        strokeColor: '#d97c1a',
        strokeWeight: 0.75,
        fillOpacity: 0.75,
        ...(isCountrySelected ? selectedPolyOptions : {}),
        ...(isHoveredPoly ? hoverPolyOptions : {}),
        ...(!isCountrySelected && !isHoveredPoly
            ? {
                fillColor: '#f7701e',
                strokeColor: '#d97c1a',
                strokeWeight: 0.75,
                fillOpacity: 0.75,
            }
            : {}),
        ...(hide
            ? {
                fillColor: '#FFF',
                fillOpacity: 0.5,
                strokeOpacity: 0.0,
                strokeWeight: 0.0,
            }
            : {}),
    };

    return (
        <Polygon
            key={polyKey}
            paths={paths}
            options={polyOptionsToRender}
            onMouseOver={onCountryHover}
            onClick={() => {
                if (!isCountrySelected) {
                    const center = country?.center?.geometry?.coordinates;

                    zoomType.current = isCountrySelected ? 'global' : 'country';

                    setCenter(
                        isCountrySelected
                            ? centerInit
                            : {
                                lat: center?.[1] || poly?.[0]?.[1],
                                lng: center?.[0] || poly?.[0]?.[0],
                            },
                    );

                    setSelectedPoly(countryId);

                    actions?.SELECT_COUNTRY?.({
                        countryId,
                        countryName:
              countriesJson?.[countryId]?.properties?.ADMIN,
                    });
                }
            }}
        />
    );
}

function RenderPolygons(props = {}) {
    const actions = useActions();
    const countriesWithPoly = useRef({});
    const polyRefs = useRef({});

    const { countriesToRender } = useFilters();
    const countryIdSelected = useSelector(
        (state) => state?.exportView?.countryId,
    );

    useEffect(() => {
        console.log({
            countryIdSelected,
            countriesToRender,
            polyRefs,
            props,
        });
    }, [countriesToRender, countryIdSelected]);

    const {
        countries: countriesRaw,
        hoveredPoly,
        selectedPoly,
        setHoveredPoly,
        selectedPolyOptions,
        zoomType,
        setCenter,
        zoomLevels,
        setSelectedPoly,
        setZoom,
        centerInit,
        hoverPolyOptions,
    } = props;

    const renderPolyProps = {
        ...props,
        actions,
        countriesWithPoly,
    };

    return (
        !selectedPoly &&
    Object.values(countriesJson).map((country) => {
        const countryId = country?.id;

        let isSelectedPoly = selectedPoly === countryId;
        let isHoveredPoly = hoveredPoly === countryId;

        // if (selectedPoly && !isSelectedPoly) {
        //   console.log(countryId, 'hiding', selectedPoly, isSelectedPoly);
        //   return null;
        // }

        const isMulti = country?.geometry?.type === 'MultiPolygon';

        return isMulti
            ? country?.geometry?.coordinates?.map((multiPoly) =>
                multiPoly?.map?.((poly) => (
                    <RenderPoly
                        {...{
                            poly,
                            countryId,
                            isSelectedPoly,
                            isHoveredPoly,
                            country,
                            hide: selectedPoly && !isSelectedPoly,
                            ...renderPolyProps,
                        }}
                    />
                )),
            )
            : country?.geometry?.coordinates?.map?.((poly) => {
                return (
                    <RenderPoly
                        {...{
                            poly,
                            countryId,
                            isSelectedPoly,
                            isHoveredPoly,
                            country,
                            hide: selectedPoly && !isSelectedPoly,
                            ...renderPolyProps,
                        }}
                    />
                );
            });
    })
    );
}

export default RenderPolygons;
