import FilterData from 'Components/Micro/FilterData';
import ExportData from 'Components/Micro/ExportData';

import useMetrics from 'Hooks/useMetrics';

import Tags from './Filters/Tags';

import { Motion, spring } from 'react-motion';

function World({ visible }) {
    const { tier1 = {}, materials = {} } = useMetrics({
        region: 'WORLD',
    });

    return visible ? (
        <li className="world-scope current-scope">
            <fieldset>
                <legend className="accessibility">
          Choose how you would like to sort each region.
                </legend>
                <h2>Where nike products are made</h2>
                <div className="accessibility faceted-selection">
                    <h3 className="accessibility">You Selected</h3>
                    <div className="brand-filters-list empty-list" />
                    <div className="product-filters-list empty-list" />
                    <div className="event-filters-list empty-list" />
                </div>
                <Tags />
                <FilterData region="WORLD" />
                <h2>FINISHED GOODS</h2>
                <Motion
                    defaultStyle={{
                        tier1CountriesCount: 0,
                        tier1CountryPercentage: 0,
                        tier1FactoriesCount: 0,
                        tier1FactoriesToGlobalPercentage: 0,
                        tier1Workers: 0,
                        tier1WorkersToGlobalPercentage: 0,
                        materialsCountriesCount: 0,
                        materialsCountryPercentage: 0,
                        materialsFactoriesCount: 0,
                        materialsFactoryPercentage: 0,
                    }}
                    style={{
                        tier1CountriesCount: spring(tier1?.countryLength),
                        tier1CountryPercentage: spring(tier1?.countryPercentage),
                        tier1FactoriesCount: spring(tier1?.factories?.length),
                        tier1FactoriesToGlobalPercentage: spring(
                            tier1?.factoriesToGlobalPercentage,
                        ),
                        tier1Workers: spring(tier1?.workers),
                        tier1WorkersToGlobalPercentage: spring(
                            tier1?.workersToGlobalPercentage,
                        ),
                        materialsCountriesCount: spring(materials?.countryLength),
                        materialsCountryPercentage: spring(
                            materials?.countryPercentage,
                        ),
                        materialsFactoriesCount: spring(
                            materials?.factories?.length,
                        ),
                        materialsFactoryPercentage: spring(
                            materials?.factoriesPercentage,
                        ),
                    }}
                >
                    {(value) => {
                        return (
                            <ul className="scope-details" style={{ width: 358 }}>
                                <li className="countries">
                                    <strong>
                                        {value.tier1CountriesCount.toFixed(0)}
                                    </strong>{' '}
                  Countries/Regions{' '}
                                    <div id="world-countries-bar" className="bar-chart">
                                        <span
                                            style={{
                                                width: `${value.tier1CountryPercentage}%`,
                                            }}
                                        />
                                    </div>{' '}
                                    <span className="percents">
                                        {value.tier1CountryPercentage.toFixed(0)}%
                                    </span>
                                </li>
                                <li className="factories">
                                    <strong>
                                        {value.tier1FactoriesCount.toFixed(0)}
                                    </strong>{' '}
                  Factories{' '}
                                    <div id="world-factories-bar" className="bar-chart">
                                        <span
                                            style={{
                                                width: `${value.tier1FactoriesToGlobalPercentage}%`,
                                            }}
                                        />
                                    </div>{' '}
                                    <span className="percents">
                                        {value.tier1FactoriesToGlobalPercentage.toFixed(
                                            0,
                                        )}
                    %
                                    </span>
                                </li>
                                <li className="workers">
                                    <strong>
                                        {value.tier1Workers
                                            .toFixed(0)
                                            ?.toString?.()
                                            ?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </strong>{' '}
                  Workers{' '}
                                    <div id="world-workers-bar" className="bar-chart">
                                        <span
                                            style={{
                                                width: `${value.tier1WorkersToGlobalPercentage}%`,
                                            }}
                                        />
                                    </div>{' '}
                                    <span className="percents">
                                        {value.tier1WorkersToGlobalPercentage.toFixed(0)}%
                                    </span>
                                </li>
                                <div className="divider" />
                                <br />
                                <div className="tier2">
                                    <h2 align="left">MATERIALS</h2>
                                    <li className="countries1">
                                        <strong>
                                            {value.materialsCountriesCount.toFixed(0)}
                                        </strong>{' '}
                    Countries/Regions{' '}
                                        <div
                                            id="world-countries-bar1"
                                            className="bar-chart"
                                        >
                                            <span
                                                style={{
                                                    width: `${value.materialsCountryPercentage}%`,
                                                }}
                                            />
                                        </div>{' '}
                                        <span className="percents">
                                            {value.materialsCountryPercentage.toFixed(0)}%
                                        </span>
                                    </li>
                                    <li className="factories1">
                                        <strong>
                                            {value.materialsFactoriesCount.toFixed(0)}
                                        </strong>{' '}
                    Facilities{' '}
                                        <div
                                            id="world-factories-bar1"
                                            className="bar-chart"
                                        >
                                            <span
                                                style={{
                                                    width: `${value.materialsFactoryPercentage}%`,
                                                }}
                                            />
                                        </div>{' '}
                                        <span className="percents">
                                            {value.materialsFactoryPercentage.toFixed(0)}%
                                        </span>
                                    </li>
                                </div>
                            </ul>
                        );
                    }}
                </Motion>
            </fieldset>
            <div className="notice">
                <p>
          Line chart % based on total NIKE Inc. factories and workers
          for finished goods production and on total strategic vendors
          for NIKE brand materials production
                </p>
            </div>
            <ExportData />
        </li>
    ) : null;
}

export default World;
