import React, { useState, useCallback } from 'react';
import useActions from 'Hooks/useActions';
import newFactoryData from 'Store/Constants/newData';
import { useSelector } from 'react-redux';
import Tabs from './tabs';

const tabsList = [
    'BRANDS',
    'PRODUCT TYPES',
    'COLLEGIATE',
    'SUPPLIER',
    'FACILITY TYPE',
];

function arrayCompare(_arr1, _arr2) {
    if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
    ) {
        return false;
    }

    // .concat() to not mutate arguments
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

function Filters({ data = [], country = '' }) {
    const actions = useActions();

    const exportView = useSelector((state) => state?.exportView);

    const [activeTab, setActiveTab] = useState('BRANDS');
    const [BRANDS, setBRANDS] = useState(exportView?.BRANDS || []);
    const [SUPPLIER, setSUPPLIER] = useState(
        exportView?.SUPPLIER || [],
    );
    const [FACILITYTYPE, setFACILITYTYPE] = useState(
        exportView?.['FACILITY TYPE'] || [],
    );
    const [PRODUCTTYPES, setPRODUCTTYPES] = useState(
        exportView?.['PRODUCT TYPES'] || [],
    );
    const [COLLEGIATE, setCOLLEGIATE] = useState(
        exportView?.COLLEGIATE || [],
    );

    const [hasFilters, setHasFilters] = useState(
        checkIfHasFilters(
            {
                BRANDS,
                SUPPLIER,
                'FACILITY TYPE': FACILITYTYPE,
                'PRODUCT TYPES': PRODUCTTYPES,
                COLLEGIATE,
            },
            true,
        ),
    );

    function checkIfHasFilters(filtersData, isUnique = false) {
        const filtersMatch = Object.keys(filtersData).reduce(
            (allFilters, currentFilterKey) => {
                const comparedArrays = arrayCompare(
                    filtersData[currentFilterKey],
                    exportView?.[currentFilterKey] || [],
                );
                const res = isUnique ? comparedArrays : true;

                return !res || allFilters;
            },
            false,
        );

        return filtersMatch;
    }

    const tabToSet = {
        BRANDS: [BRANDS, setBRANDS],
        SUPPLIER: [SUPPLIER, setSUPPLIER],
        FACILITYTYPE: [FACILITYTYPE, setFACILITYTYPE],
        PRODUCTTYPES: [PRODUCTTYPES, setPRODUCTTYPES],
        COLLEGIATE: [COLLEGIATE, setCOLLEGIATE],
    };

    function resetFilters() {
        setBRANDS([]);
        setSUPPLIER([]);
        setCOLLEGIATE([]);
        setPRODUCTTYPES([]);
        setFACILITYTYPE([]);
        setHasFilters(false);
        actions.UPDATE_FILTERS({
            BRANDS: [],
            SUPPLIER: [],
            'FACILITY TYPE': [],
            'PRODUCT TYPES': [],
            COLLEGIATE: [],
        });
    }

    function cancelFilterChanges() {
        setBRANDS(exportView?.BRANDS || []);
        setSUPPLIER(exportView?.SUPPLIER || []);
        setCOLLEGIATE(exportView?.COLLEGIATE || []);
        setPRODUCTTYPES(exportView?.['PRODUCT TYPES'] || []);
        setFACILITYTYPE(exportView?.['FACILITY TYPE'] || []);
        setHasFilters(false);
    }

    const reduceData = useCallback(function (
        allFactoryResponse,
        factoryData,
    ) {
        const cleanActiveTab = activeTab.replace(' ', '');

        switch (cleanActiveTab) {
            case 'BRANDS': {
                if (!factoryData?.brand) {
                    return allFactoryResponse;
                }
                const newFactoryBrands = factoryData.brand
                    .split(', ')
                    .reduce((allBrands, currentBrand) => {
                        return {
                            ...allBrands,
                            [currentBrand]: true,
                        };
                    }, {});
                return {
                    ...allFactoryResponse,
                    ...newFactoryBrands,
                };
            }
            case 'PRODUCTTYPES': {
                if (!factoryData?.product_type) {
                    return allFactoryResponse;
                }
                const newFactoryBrands = factoryData.product_type
                    .split(', ')
                    .reduce((allBrands, currentBrand) => {
                        return {
                            ...allBrands,
                            [currentBrand]: true,
                        };
                    }, {});

                return {
                    ...allFactoryResponse,
                    ...newFactoryBrands,
                };
            }
            case 'COLLEGIATE': {
                if (!factoryData?.special_events) {
                    return allFactoryResponse;
                }
                const newFactoryBrands = factoryData.special_events
                    .split(', ')
                    .reduce((allBrands, currentBrand) => {
                        return {
                            ...allBrands,
                            [currentBrand]: true,
                        };
                    }, {});

                return {
                    ...allFactoryResponse,
                    ...newFactoryBrands,
                };
            }
            case 'SUPPLIER': {
                if (JSON.stringify(allFactoryResponse).toUpperCase().indexOf(factoryData?.supplier_name.trim().toUpperCase()) != -1) {
                    return allFactoryResponse;
                }
                const newFactoryBrands = factoryData.supplier_name.toLowerCase()
                    .split(', ')
                    .reduce((allBrands, currentBrand) => {
                        return {
                            ...allBrands,
                            [currentBrand]: true,
                        };
                    }, {});

                return {
                    ...allFactoryResponse,
                    ...newFactoryBrands,
                };
            }
            case 'FACILITYTYPE': {

                if (!factoryData?.factory_tier) {
                    return allFactoryResponse;
                }
                const newFactoryBrands = factoryData.factory_tier
                    .split(', ')
                    .reduce((allBrands, currentBrand) => {
                        return {
                            ...allBrands,
                            [currentBrand]: true,
                        };
                    }, {});

                return {
                    ...allFactoryResponse,
                    ...newFactoryBrands,
                };
            }
            default: {
                return allFactoryResponse;
            }
        }
    });

    function toggleOption(optionId) {
        const cleanTab = activeTab.replace(' ', '');

        const currentTabFilters = tabToSet[cleanTab][0];
        let newTabFilters;

        if (currentTabFilters.includes(optionId)) {
            newTabFilters = currentTabFilters.filter(
                (tabFilter) => tabFilter !== optionId,
            );
            tabToSet[cleanTab][1]([...newTabFilters]);
        } else {
            newTabFilters = [...currentTabFilters, optionId];
            tabToSet[cleanTab][1](newTabFilters);
        }

        const newFilterObjectToCompare = {
            BRANDS,
            SUPPLIER,
            'FACILITY TYPE': FACILITYTYPE,
            'PRODUCT TYPES': PRODUCTTYPES,
            COLLEGIATE,
            [activeTab]: newTabFilters,
        };

        if (checkIfHasFilters(newFilterObjectToCompare, true)) {
            setHasFilters(true);
        } else {
            setHasFilters(false);
        }
    }

    return (
        <li
            className="filter-scope able-to-clear active-scope"
            style={{
                height: 758,
                maxHeight: 355,
                overflow: 'visible',
                padding: 0,
                width: 350,
            }}
        >
            <div
                className="jspContainer"
                style={{ width: 350, height: 355 }}
            >
                <div
                    className="jspPane"
                    style={{ padding: 15, top: 0, width: 320 }}
                >
                    <h3>Filters</h3>
                    <a
                        href="#"
                        className="close"
                        onClick={() => {
                            actions?.TOGGLE_FILTERS_VIEW?.();
                        }}
                    >
                        Close X
                    </a>
                    <ul className="filter-nav">
                        {tabsList.map((tab) => {
                            const isActiveTab = tab === activeTab;

                            return (
                                <li
                                    key={tab}
                                    className={`${tab.toLowerCase()}-filters ${isActiveTab ? 'current' : ''
                                        }`}
                                >
                                    <a
                                        href="#"
                                        title={`${tab} filters`}
                                        onClick={() => {
                                            setActiveTab(tab);
                                        }}
                                    >
                                        {tab.toUpperCase()}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>

                    <Tabs
                        toggleOption={toggleOption}
                        activeTab={activeTab}
                        tabOptions={() =>
                            (country ? data : newFactoryData).reduce(reduceData, {})
                        }
                        {...{
                            BRANDS,
                            SUPPLIER,
                            FACILITYTYPE,
                            PRODUCTTYPES,
                            COLLEGIATE,
                        }}
                    />

                    <a
                        href="#"
                        className={`apply-filters ${hasFilters ? '' : 'disabled'
                            }`}
                        onClick={() => {
                            actions.UPDATE_FILTERS({
                                BRANDS,
                                SUPPLIER,
                                'FACILITY TYPE': FACILITYTYPE,
                                'PRODUCT TYPES': PRODUCTTYPES,
                                COLLEGIATE,
                            });
                            setHasFilters(false);
                        }}
                    >
                        apply
                    </a>
                    <span className="cancel-filters">
                        or{' '}
                        <a href="#" onClick={cancelFilterChanges}>
                            cancel
                        </a>
                    </span>
                    <p className="notice">
                        <a
                            href="#"
                            className="clear-all-filters"
                            onClick={resetFilters}
                        >
                            Clear all filters
                        </a>
                    </p>
                </div>
            </div>
        </li>
    );
}

export default Filters;
