import factoryTiers from 'Store/Constants/factoryTiers';

function useToggleFilter(props = {}) {
    function onToggle(optionId) {
        return () => props?.toggleOption?.(optionId);
    }

    return onToggle;
}

function Tabs(props = {}) {
    const onToggle = useToggleFilter(props);

    const { activeTab } = props;

    const tabOptions = props?.tabOptions?.() || {};

    const cleanActiveTab = activeTab.replace(' ', '');

    const currentOptions = (props[cleanActiveTab] || []).sort();

    return (
        <fieldset className={`${activeTab.toLowerCase()}-filters pane`}>
            <legend className="accessibility">
        Use these facets below to filter our results by brands.
            </legend>
            <div style={{ height: '13em', overflow: 'auto' }}>
                <ol className="nav">
                    {Object.keys(tabOptions)
                        .sort()
                        .filter((option) => !tabOptions[tabOptions])
                        .map((option) => {
                            const optionLabelAndKey = option.toUpperCase();

                            const isActiveOption = currentOptions.includes(
                                optionLabelAndKey,
                            );

                            let optionName = optionLabelAndKey;

                            if (cleanActiveTab === 'FACILITYTYPE') {
                                optionName =
                  factoryTiers?.[option] || optionLabelAndKey;
                            }
                            return (
                                <li key={optionLabelAndKey}>
                                    <span id={optionLabelAndKey}>{optionName}</span>
                                    <span
                                        onClick={onToggle(optionLabelAndKey)}
                                        className={`tmp-filter-slider ${
                                            isActiveOption ? 'active' : ''
                                        }`}
                                    />
                                </li>
                            );
                        })}
                </ol>
            </div>
        </fieldset>
    );
}

export default Tabs;
