import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'Hooks/useActions';

import Country from './Country';
import Factory from './Factory';
import World from './World';
import ChooseFactory from './ChooseFactory';
import ChooseCountry from './ChooseCountry';
import Filters from './Filters';

import newFactoryData from 'Store/Constants/newData';
import countries from 'Store/Constants/newBorders';
import regionTypes from 'Store/Constants/regionTypes';

function Breadcrumbs({
    country,
    factory,
    countryLabel,
    visible,
    onCountry,
    onFactory,
    factoryObject,
    onChooseALocation,
    onGlobePress,
    onChooseCountryRegion,
}) {
    let countryDisabled = '';
    let worldDisabled = '';

    if (!factory && country) {
        countryDisabled = 'disabled';
    } else {
        countryDisabled = '';
    }

    if (country || factory) {
        worldDisabled = '';
    } else {
        worldDisabled = 'disabled';
    }

    return visible ? (
        <div className="breadcrumbs">
            <a
                onClick={onGlobePress}
                href="#"
                className={`worldcrumb ${worldDisabled}`}
                id="worldcrumb"
            >
        World
            </a>

            <a
                onClick={
                    countryLabel || country ? onCountry : onChooseCountryRegion
                }
                className={`countrycrumb ${countryDisabled}`}
            >
                {countryLabel || country || 'Choose Country / Region'}
            </a>

            {country ? (
                <a
                    onClick={
                        factoryObject?.name || factory
                            ? onFactory
                            : onChooseALocation
                    }
                    className={`factorycrumb ${factory ? 'disabled' : ''}`}
                >
                    {factoryObject?.name?.length > 15
                        ? `${factoryObject.name.slice(0, 15)}...`
                        : factoryObject?.name || factory || 'Choose a location'}
                </a>
            ) : null}
        </div>
    ) : null;
}

function Region(props = {}) {
    const {
        country,
        countryObject,
        countryLabel,
        onGlobe,
        setFactory,
        setCountry,
        factoryObject,
        selectedFactory,
    } = props;

    const filtersOpen = useSelector(
        (state) => state?.exportView?.filtersOpen,
    );

    const {
        countryToData,
        hasFilters,
        region = regionTypes.WORLD,
    } = useSelector((state) => {
        return {
            intro: state?.intro,
            countryToData: state?.countryToData,
            hasFilters: state?.exportView?.hasFilters,
            region: state?.exportView?.region,
        };
    });

    const actions = useActions();

    const [factoryPicker, setFactoryPicker] = useState(false);
    const [countryPicker, setCountryPicker] = useState(false);

    const [factoryVisible, setFactoryVisible] = useState(
        !!selectedFactory,
    );
    const [countryVisible, setCountryVisible] = useState(
        !selectedFactory && !!country,
    );
    const [worldVisible, setWorldVisible] = useState(true);

    function closeAllDetails() {
        setWorldVisible(false);
        setCountryVisible(false);
        setFactoryVisible(false);
    }

    function closeAllDetailsExcept(regionToShow = 'WORLD') {
        const regionNextStates = {
            WORLD: false,
            COUNTRY: false,
            FACTORY: false,
        };

        regionNextStates[regionToShow] = true;

        setWorldVisible(regionNextStates.WORLD);
        setCountryVisible(regionNextStates.COUNTRY);
        setFactoryVisible(regionNextStates.FACTORY);
    }

    function onChooseALocation() {
        closeAllDetails();
        setFactoryPicker(true);
    }

    function onChooseCountryRegion() {
        setCountryPicker(true);
        closeAllDetailsExcept('COUNTRY');
    }

    function onGlobePress() {
        onGlobe();
        closeAllDetails();
        setWorldVisible(true);
    }

    function onFactoryPick(factoryId) {
        setFactory(factoryId);
        setFactoryPicker(false);
        setFactoryVisible(true);

        actions?.SELECT_FACTORY?.({
            factoryId,
        });
    }

    function onCountryPick(countryId) {
        setFactory('');
        setFactoryPicker(false);
        setFactoryVisible(false);
        setCountry(countryId);
        setCountryPicker(false);

        actions?.SELECT_COUNTRY?.({
            countryId,
            countryName: countries?.[countryId]?.properties?.ADMIN,
        });
    }

    function onClosePicker() {
        setFactoryPicker(false);
        setCountryPicker(false);
        const regionNextStates = {
            WORLD: false,
            COUNTRY: false,
            FACTORY: false,
        };

        regionNextStates[region] = true;

        setWorldVisible(regionNextStates.WORLD);
        setCountryVisible(regionNextStates.COUNTRY);
        setFactoryVisible(regionNextStates.FACTORY);
    }

    useEffect(() => {
        closeAllDetailsExcept(region);
    }, [region]);

    return (
        <main
            id="region-form-wrapper"
            style={{ width: filtersOpen ? 740 : 375 }}
            role="main"
        >
            <div
                id="region-form"
                method="post"
                style={{ width: 375 }}
                action="/maps/get_data"
            >
                <div className="inner">
                    <fieldset>
                        <Breadcrumbs
                            visible={
                                countryVisible || factoryVisible || worldVisible
                            }
                            onChooseALocation={onChooseALocation}
                            onChooseCountryRegion={onChooseCountryRegion}
                            onGlobePress={onGlobePress}
                            {...props}
                        />
                        <legend className="accessibility">
              Use this form to filter data on the map and export the
              data results.
                        </legend>
                        <ol className="facets">
                            {factoryPicker ? (
                                <ChooseFactory
                                    data={countryToData?.[country]?.data}
                                    visible={factoryPicker}
                                    onClosePicker={onClosePicker}
                                    onPick={onFactoryPick}
                                    {...{ hasFilters }}
                                />
                            ) : null}
                            {countryPicker ? (
                                <ChooseCountry
                                    data={Object.values(countries)}
                                    visible={countryPicker}
                                    onClosePicker={onClosePicker}
                                    onPick={onCountryPick}
                                    {...{ hasFilters }}
                                />
                            ) : null}
                            {worldVisible ? (
                                <World visible={worldVisible} {...{ hasFilters }} />
                            ) : null}
                            {countryVisible ? (
                                <Country
                                    visible={countryVisible}
                                    country={countryObject}
                                    name={countryLabel}
                                    data={countryToData?.[country]?.data}
                                    onChooseCountryRegion={onChooseCountryRegion}
                                    {...{ hasFilters }}
                                />
                            ) : null}
                            {factoryVisible ? (
                                <Factory
                                    visible={factoryVisible}
                                    factory={factoryObject}
                                    name={factoryObject?.name}
                                    onChooseALocation={onChooseALocation}
                                    {...{ hasFilters }}
                                />
                            ) : null}
                            {filtersOpen ? (
                                <Filters
                                    country={country}
                                    data={
                                        countryToData?.[country]?.data || newFactoryData
                                    }
                                />
                            ) : null}
                        </ol>
                    </fieldset>
                </div>
                <div className="filter-inputs">
                    <div id="brand-filters"></div>
                    <div id="product-filters"></div>
                    <div id="event-filters"></div>
                    <div id="supplier-filters"></div>
                    <div id="tier-filters"></div>
                </div>
            </div>
        </main>
    );
}

export default React.memo(Region);
