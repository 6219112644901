import React, { useRef } from 'react';

import percentage from 'Utils/percentage';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function CountryProfile({ productTypesMetrics, productTypesTotal }) {
    console.log("Apparel values:")
    console.log(productTypesMetrics.Apparel)

    const chartRef = useRef(null);
    const percentages = {
        APPAREL: productTypesTotal ? percentage(
            productTypesMetrics.Apparel,
            productTypesTotal
        ) : 0,
        FOOTWEAR: productTypesTotal ? percentage(
            productTypesMetrics.Footwear,
            productTypesTotal
        ) : 0,
        EQUIPMENT: productTypesTotal ? percentage(
            productTypesMetrics.Equipment,
            productTypesTotal
        ) : 0,
    };
    const defaultData = [
        {
            y: productTypesMetrics.APPAREL,
            color: 'rgb(255,158,81)',
            drilldown: {
                name: '',
                data: [productTypesMetrics.APPAREL],
                color: '#000000',
            },
        },
        {
            y: productTypesMetrics.EQUIPMENT,
            color: '#FFFFFF',
            drilldown: {
                name: '',
                data: [productTypesMetrics.EQUIPMENT],
                color: '#000000',
            },
        },
        {
            y: productTypesMetrics.FOOTWEAR,
            color: '#999',
            drilldown: {
                name: '',
                data: [productTypesMetrics.FOOTWEAR],
                color: '#000000',
            },
        },
    ];
    const options = {
        chart: {
            backgroundColor: null,
            background: 'transparent',
            type: 'pie',
            width: 100,
            height: 110,
            position: 'absolute',
        },
        plotOptions: {
            area: {
                fillColor: '#ff0000',
                fillOpacity: '1',
            },
            pie: {
                shadow: false,
                backgroundColor: 'transparent',
                background: 'transparent',
                borderColor: '#777',
            },
            series: {
                // point: {
                //   events: {
                //     mouseOver: (...args) => {
                //       console.log('mouseOver', args);
                //     },
                //   },
                // },
                states: {
                    hover: {
                        halo: {
                            size: 0,
                            attributes: {},
                        },
                    },
                },
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            enabled: false,
        },
        series: [
            {
                name: '',
                innerSize: '35%',
                size: '100%',
                data: defaultData.reverse(),
                dataLabels: {
                    enabled: false,
                },
            },
        ],
    };

    return (
        <div className="profile">
            <h3>Type of product made</h3>

            <ul className="manufacture-profile">
                <li>
                    Apparel{' '}
                    <span className="total">{productTypesMetrics.APPAREL}</span>
                    <span className="key-color apparel" />
                    {
                        // <strong>
                        //     {percentage(
                        //         productTypesMetrics.APPAREL,
                        //         productTypesTotal.APPAREL
                        //     )}
                        //     %
                        // </strong>
                        //<strong>{percentages.APPAREL}%</strong>
                        //<strong>{productTypesTotal.APPAREL}</strong>
                    }
                </li>
                <li>
                    Equipment{' '}
                    <span className="total">
                        {productTypesMetrics.EQUIPMENT}
                    </span>
                    <span className="key-color equipment" />
                    {/* <strong>
                        {percentage(
                            productTypesMetrics.EQUIPMENT,
                            productTypesTotal.EQUIPMENT
                        )}
                        %
                    </strong> */}
                </li>
                <li>
                    Footwear{' '}
                    <span className="total">
                        {productTypesMetrics.FOOTWEAR}
                    </span>
                    <span className="key-color footwear" />
                    {/* <strong>
                        {percentage(
                            productTypesMetrics.FOOTWEAR,
                            productTypesTotal.FOOTWEAR
                        )}
                        %
                    </strong> */}
                </li>
                <li id="country_piechart">
                    <div
                        className="highcharts-container"
                        id="highcharts-2"
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            width: 100,
                            height: 110,
                            textAlign: 'left',
                            fontFamily:
                                '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
                            fontSize: 12,
                        }}
                    >
                        {Highcharts ? (
                            <HighchartsReact
                                ref={chartRef}
                                constructorType={'chart'}
                                allowChartUpdate={true}
                                containerProps={{
                                    className: 'chartContainer',
                                    style: {
                                        width: 100,
                                        height: 110,
                                    },
                                }}
                                updateArgs={[true, true, true]}
                                highcharts={Highcharts}
                                options={options}
                                callback={(...args) => console.log('callback', args)}
                            />
                        ) : null}
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default CountryProfile;
