const hoverPolyOptions = {
    fillColor: '#fab368',
    strokeColor: '#d97c1a',
    strokeWeight: 0.75,
    fillOpacity: 0.55,
};

const selectedPolyOptions = {
    fillColor: '#fab368',
    strokeColor: '#d97c1a',
    strokeWeight: 2,
    fillOpacity: 0.55,
};

function getPaths(poly) {
    return poly?.map?.((coords) => {
        const [oldLng, oldLat] = coords || [];
        const lat = parseFloat(Number(oldLat));
        const lng = parseFloat(Number(oldLng));

        return { lat, lng };
    });
}

function makePolygonList(countries, selectedPoly, hoveredPoly) {
    return Object.values(countries).map((country) => {
        const countryId = country?.id;
        const isCountrySelected = selectedPoly === countryId;
        const isHoveredPoly = hoveredPoly === countryId;
        const isMulti = country?.geometry?.type === 'MultiPolygon';

        const paths = isMulti
            ? country?.geometry?.coordinates?.map((multiPoly) =>
                multiPoly?.map?.(getPaths),
            )
            : country?.geometry?.coordinates?.map?.(getPaths);

        const center = country?.center?.geometry?.coordinates;

        const polyOptionsToRender = {
            fillColor: '#f7701e',
            strokeColor: '#d97c1a',
            strokeWeight: 0.75,
            fillOpacity: 0.75,
            ...(isCountrySelected ? selectedPolyOptions : {}),
            ...(isHoveredPoly ? hoverPolyOptions : {}),
            ...(!isCountrySelected && !isHoveredPoly
                ? {
                    fillColor: '#f7701e',
                    strokeColor: '#d97c1a',
                    strokeWeight: 0.75,
                    fillOpacity: 0.75,
                }
                : {}),
        };

        return {
            paths,
            id: countryId,
            countryId,
            center,
            polyOptionsToRender,
            isMulti,
        };
    });
}

export default makePolygonList;
