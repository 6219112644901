import React from 'react';
import configureStore from 'Store';
import { Provider } from 'react-redux';
import { leaveBreadcrumb } from 'Utils';
import Routing from 'Routing';
import ActionTypes from 'Store/ActionTypes';

import 'index.css';

const [store] = configureStore();

function App() {
    leaveBreadcrumb('App initiated.');

    React.useEffect(() => {
        store.dispatch({
            type: ActionTypes.APP_LAUNCH,
        });
    }, []);

    return (
        <Provider store={store}>
            <Routing />
        </Provider>
    );
}

export default App;
