import useActions from 'Hooks/useActions';

import { useSelector } from 'react-redux';

function FilterData(props = {}) {
    const actions = useActions();

    const hasFilters = useSelector(
        (state) => state?.exportView?.hasFilters,
    );

    const { data, region } = props;
    return (
        <ul className="nav">
            <li className="toplevel-filters">
                <a
                    href="#"
                    onClick={() => {
                        const filterProps = data?.() || {};
                        actions?.TOGGLE_FILTERS_VIEW?.(filterProps);
                    }}
                >
                    {hasFilters ? 'MODIFY FILTERS APPLIED' : 'Filter Data'}
                </a>
            </li>
        </ul>
    );
}

export default FilterData;
