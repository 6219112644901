import actionTypes from 'Assets/actionTypes';
import envConfig from 'Assets/config/env';

const { SWITCH_ENV } = actionTypes || {};

function EnvReducer(state = 'dev', action = {}) {
    const { env = envConfig?.id } = action;

    switch (action?.type) {
    case SWITCH_ENV: {
        return env;
    }
    default: {
        return state;
    }
    }
}

export default EnvReducer;
