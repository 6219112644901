import { combineReducers } from 'redux';
import env from './env';
import about from './about';
import intro from './intro';
import config from './config';
import countryToData from './countryToData';
import exportView from './exportView';

export default combineReducers({
    env,
    about,
    intro,
    config,
    exportView,
    countryToData,
});
