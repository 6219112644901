import ActionTypes from 'Store/ActionTypes';
import ActionStatus from 'Store/ActionStatus';

function CountryToDataReducer(state = {}, action = {}) {
    if (
        action?.type === ActionStatus.SUCCESS &&
    action?.origin === ActionTypes.APP_LAUNCH &&
    action?.data?.countryToData
    ) {
        return {
            ...action.data.countryToData,
        };
    }
    return state;
}

export default CountryToDataReducer;
