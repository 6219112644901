function ShowPercentageLabel({ data }) {
    return data?.split('.')?.map((fWP, fWPIndex) => {
        if (fWPIndex) {
            return <sup key={fWPIndex}>.{fWP}</sup>;
        } else {
            return <span key={fWPIndex}>{fWP}</span>;
        }
    });
}

export default ShowPercentageLabel;
