import actionTypes from 'Store/ActionTypes';

const { TOGGLE_ABOUT } = actionTypes;

const initialState = {
    isOpen: false,
};

function AboutReducer(state = initialState, action = {}) {
    let isOpen;
    switch (action?.type) {
    case TOGGLE_ABOUT: {
        isOpen = state.isOpen;
        if (!action?.data && !state.isOpen) {
            isOpen = true;
        } else if (action?.data) {
            isOpen = !state.isOpen;
        }
        return {
            ...state,
            isOpen,
        };
    }
    default: {
        return state;
    }
    }
}

export default AboutReducer;
