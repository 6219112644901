import useFilters from 'Hooks/useFilters';
import { Scrollbars } from 'react-custom-scrollbars-2';

function ChooseCountry({
    data = [],
    visible,
    onClosePicker,
    onPick,
}) {
    function onPicking(id) {
        return () => {
            onPick(id);
        };
    }

    const {
        onlyFilteredCountries: countriesToRender = {},
    } = useFilters();

    const renderData = data;

    function renderTrackVertical(...trackVProps) {
        console.log({ trackVProps });
        return <div></div>;
    }

    return visible ? (
        <li className="scope-selection world current-scope">
            <fieldset>
                <a
                    href="#"
                    onClick={onClosePicker}
                    className="close-selection"
                >
          Close X
                </a>
                <h2>Select a Country/Region</h2>
                <ul className="sort-nav">
                    <li className="current">
                        <a href="#" className="sort-by name">
              By name
                        </a>
                    </li>
                    {/*<li><a href="#" class="sort-by region">By region</a></li>*/}
                </ul>
                <legend className="accessibility">
          Choose what region you would like to filter the map by.
                </legend>
                {/* 							<ol onclick="change()"></ol> */}
                <Scrollbars
                    style={{ width: '100%', height: window.innerHeight / 2 }}
                    renderTrackVertical={renderTrackVertical}
                    universal={true}
                >
                    <ol className="jspScrollable" tabIndex={0}>
                        <div
                            className="jspContainer"
                            style={{ width: 358, height: 568, overflowY: 'auto' }}
                        >
                            <div
                                className="jspPane"
                                style={{ padding: 0, width: 345, top: 0 }}
                            >
                                {renderData
                                    ?.sort(function (a, b) {
                                        //console.log({ a, b });
                                        var textA = a?.properties?.ADMIN.toUpperCase();
                                        var textB = b?.properties?.ADMIN.toUpperCase();
                                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                                    })
                                    ?.map((item) => {
                                        const id = item?.id;
                                        if (!countriesToRender?.[id]) {
                                            return null;
                                        }
                                        const name =
                      item?.name || item?.properties?.ADMIN;
                                        return (
                                            <li key={id} onClick={onPicking(id)}>
                                                <label>{name}</label>
                                            </li>
                                        );
                                    })}
                            </div>
                            {/* <div className="jspVerticalBar">
                <div className="jspCap jspCapTop" />
                <div className="jspTrack" style={{ height: 568 }}>
                  <div className="jspDrag" style={{ height: 173 }}>
                    <div className="jspDragTop" />
                    <div className="jspDragBottom" />
                  </div>
                </div>
                <div className="jspCap jspCapBottom" />
              </div> */}
                        </div>
                    </ol>
                </Scrollbars>
            </fieldset>
        </li>
    ) : null;
}

export default ChooseCountry;
