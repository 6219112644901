import actionTypes from 'Store/ActionTypes';
import regionTypes from 'Store/Constants/regionTypes';

const {
    TOGGLE_EXPORT_VIEW,
    TOGGLE_FILTERS_VIEW,
    UPDATE_FILTERS,
    SELECT_COUNTRY,
    SELECT_FACTORY,
} = actionTypes;

const initialState = {
    isOpen: false,
    filtersOpen: false,
    filters: '',
    region: regionTypes.WORLD,
    hasFilters: false,
};

function ExportViewReducer(state = initialState, action = {}) {
    const { countryId } = action?.data || {};
    switch (action?.type) {
    case SELECT_COUNTRY: {
        return {
            ...state,
            countryId: action?.data?.countryId || '',
            countryName: action?.data?.countryName || '',
            region: countryId ? regionTypes.COUNTRY : regionTypes.WORLD,
        };
    }
    case TOGGLE_EXPORT_VIEW: {
        return {
            ...state,
            countryId: action?.data?.countryId || '',
            countryName: action?.data?.countryName || '',
            isOpen: !state.isOpen,
        };
    }
    case SELECT_FACTORY: {
        return {
            ...state,
            factoryId: action?.data?.factoryId,
            region: regionTypes.FACTORY,
        };
    }
    case UPDATE_FILTERS: {
        const data = action?.data || {};
        const hasFilters = Object.values(data).reduce(
            (allFilters, currentFilter) => {
                if (currentFilter.length) {
                    return true;
                }
                return allFilters;
            },
            false,
        );
        return {
            ...state,
            ...data,
            hasFilters,
            filters: Object.values(data).reduce(
                (allFilters, currentFilter) => {
                    const startFilters = allFilters.length;
                    const newFilters = currentFilter.length;
                    return (
                        allFilters +
              (startFilters && newFilters ? ', ' : '') +
              currentFilter.join(', ')
                    );
                },
                '',
            ),
        };
    }
    case TOGGLE_FILTERS_VIEW: {
        return {
            ...state,
            ...(action?.data || {}),
            filtersOpen: !state.filtersOpen,
        };
    }
    default: {
        return state;
    }
    }
}

export default ExportViewReducer;
