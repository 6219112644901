import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'Hooks/useWindowDimensions';
import useActions from 'Hooks/useActions';

import { Motion, spring } from 'react-motion';

function AboutComponent({ isOpen, motion, height, actions }) {
    return (
        <section
            className={`legend-key ${isOpen}`}
            style={{ height, top: motion.y }}
        >
            <a
                href="#"
                onClick={() => {
                    actions.TOGGLE_ABOUT(true);
                }}
                className="close-legend"
                title="Close Legend View"
            >
        Close About View
            </a>
            <div
                className="wrapper jspScrollable"
                style={{
                    height: '763px',
                    maxHeight: '920px',
                    overflow: 'hidden',
                    padding: '0px',
                    width: '980px',
                }}
                tabIndex="0"
            >
                <div
                    className="jspContainer"
                    style={{
                        width: 'auto',
                        height: '763px',
                        overflowY: 'auto',
                    }}
                >
                    <div
                        className="jspPane"
                        style={{ padding: '0px', top: '0px', width: 'auto' }}
                    >
                        <div className="primary">
                            <h2>
                Learn more about NIKE, Inc. contract manufacturing
                network and countries where we do business
                            </h2>
                            <h3>About this site</h3>
                            <p>
                Virtually all NIKE, Inc. materials and finished
                products are sourced from, or manufactured by,
                independent suppliers that also make or provide
                products for other global companies. That means that
                NIKE does not own, operate or employ workers at these
                locations. NIKE also has license agreements that
                permit unaffiliated parties to manufacture and sell
                products using NIKE-owned trademarks.
                            </p>

                            <p>
                The suppliers featured on this map are those used by
                NIKE, Inc., licensees or agents to manufacture
                finished goods, as well as materials suppliers.
                            </p>

                            <p>
                The NIKE, Inc. company portfolio includes Nike, Jordan
                Brand, Hurley and Converse. The data on this site is
                reflective of, and can be filtered by, Nike (which
                includes the Jordan Brand), Converse and Hurley.
                            </p>

                            <p>
                Contract factories that manufacture finished goods for
                NIKE, Inc. provide the data, which is then validated
                when audits are performed during the year. These
                suppliers are required to adhere to a rigorous set of
                compliance standards, including NIKE’s{' '}
                                <a
                                    href="https://sustainability-nike.s3.amazonaws.com/wp-content/uploads/2018/05/14214943/Nike_Code_of_Conduct_2017_English.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                  Code of Conduct
                                </a>{' '}
                and{' '}
                                <a
                                    href="https://sustainability-nike.s3.amazonaws.com/wp-content/uploads/2018/05/14214943/Nike-Code-Leadership-Standards-September-2017-English.pdf"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                  Code Leadership Standards
                                </a>
                . This site is updated quarterly.
                            </p>

                            <p>
                To learn more about this work, visit{' '}
                                <a href="https://sustainability.nike.com/">
                  sustainability.nike.com
                                </a>
                .
                            </p>

                            <h3>Key Terms</h3>
                            <p>
                                <strong>Workers:</strong> : The total number of
                workers is calculated based on the total number of
                employees at each contract factory. This includes line
                workers (involved in the production of NIKE products)
                and all other employees (such as managers,
                administrative staff, Human Resources employees and
                maintenance staff).
                            </p>
                            <p>
                The average age and percentage of female and migrant
                workers are calculated based on the percentage of
                total line workers.
                            </p>
                            <p>
                                <strong>Migrants:</strong> : For the purposes of this
                site, migrant workers refer to workers who have
                migrated from one country to another (foreign
                migration), as well as people within a country who
                have moved from one location to another within its
                borders (internal migration).
                            </p>
                            <p>
                                <strong>Supplier Group:</strong> Where applicable,
                this site lists the supplier group name for the
                contract factories shown on this map. A supplier group
                includes a parent entity and all of the subsidiary
                entities the parent company directly or indirectly
                owns or controls.
                            </p>
                            <div className="footer">
                                <h3>Brands Represented in the map:</h3>
                                <img
                                    src="/img/css/brand-logo-1.png"
                                    className="footer-brands"
                                    alt="Nike"
                                />
                                <img
                                    src="/img/css/brand-logo-3.png"
                                    className="footer-brands"
                                    alt="Convers"
                                />
                                <img
                                    src="/img/css/brand-logo-4.png"
                                    className="footer-brands"
                                    alt="Hurley"
                                />
                            </div>
                        </div>
                        <div className="secondary">
                            <h3>Legend</h3>
                            <ul>
                                <li className="factory-key">Location</li>
                                <li className="manufacturer-key">
                  Countries/Regions where Nike product is made
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="jspVerticalBar">
            <div className="jspCap jspCapTop"></div>
            <div className="jspTrack" style={{ height: '763px' }}>
              <div
                className="jspDrag"
                style={{ height: '681px', top: '0px' }}
              >
                <div className="jspDragTop"></div>
                <div className="jspDragBottom"></div>
              </div>
            </div>
            <div className="jspCap jspCapBottom"></div>
          </div> */}
                </div>
            </div>
        </section>
    );
}

function About() {
    const isOpen = useSelector((state) => state?.about?.isOpen);

    const { height } = useWindowDimensions();

    const actions = useActions();

    return (
        <Motion
            defaultStyle={{ y: height, isOpen: 0 }}
            style={{
                y: spring(!isOpen ? height - 47 : 47),
                isOpen: spring(isOpen ? 1 : 0),
            }}
        >
            {(value) => {
                const shouldRenderExportView = value.isOpen === 0 && !isOpen;

                return shouldRenderExportView ? null : (
                    <AboutComponent
                        {...{ isOpen, motion: value, height, actions }}
                    />
                );
            }}
        </Motion>
    );
}

export default React.memo(About);
