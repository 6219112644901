import { useDispatch } from 'react-redux';
import actionTypes from 'Store/ActionTypes';

function useActions() {
    const dispatch = useDispatch();

    return Object.keys(actionTypes)?.reduce((actions, type) => {
        const allActions = actions || {};
        allActions[type] = (data) => {
            dispatch({
                type,
                data,
            });
        };
        return allActions;
    }, {});
}

export default useActions;
