import factoryTierToName from 'Store/Constants/factoryTiers';



function Factory({ visible, name, factory, onChooseALocation }) {
    return visible ? (
        <li
            className="factory-scope current-scope"
            style={{
                height: 638,
                maxHeight: 415,
                overflow: 'hidden',
                padding: 0,
                width: 350,
            }}
        >
            <div
                className="jspContainer"
                style={{ width: 350, height: 415 }}
            >
                <div
                    className="jspPane"
                    style={{ padding: '0px 15px 15px', top: 0, width: 320 }}
                >
                    <a
                        href="#"
                        className="step-back"
                        onClick={onChooseALocation}
                    >
                        Choose location
                    </a>
                    <h2>{name}</h2>
                    <ul className="profile">
                        <li>
                            <ul className="products">
                                <li>
                                    Supplier Group{' '}
                                    <strong>{factory?.supplier_name}</strong>
                                </li>
                            </ul>
                            <ul className="tiername1">
                                <li>
                                    Facility type{' '}
                                    <strong>
                                        {factoryTierToName?.[factory?.factory_tier] ||
                                            factory?.factory_tier}
                                    </strong>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul className="numbers">
                                <li>
                                    Employees{' '}
                                    <strong className="count">
                                        {factory?.workers}
                                    </strong>
                                </li>
                                <li>
                                    Line Workers{' '}
                                    <strong className="count">
                                        {factory?.workers_line}
                                    </strong>
                                </li>
                                <li>
                                    Female{' '}

                                    <strong className="count">
                                        {

                                            String(factory?.workers_female)
                                        }
                                        <sup>{'%'}</sup>
                                    </strong>

                                </li>
                                <li>
                                    Migrant{' '}
                                    <strong className="count">
                                        {
                                            String(factory?.workers_migrant)
                                        }
                                        <sup>{'%'}</sup>
                                    </strong>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li className="brands">
                                    Brand(s)<strong>{factory?.brand}</strong>
                                </li>
                                <li className="products">
                                    Product Type{' '}
                                    <strong>{factory?.product_type}</strong>
                                </li>
                            </ul>
                        </li>
                        <li className="address">
                            <div className="data-label">Address</div>{' '}
                            <span>
                                {factory?.address && factory?.city && factory?.state
                                    ? `${factory?.address || ''} ${factory?.city || ''
                                    }, ${factory?.state || ''} ${factory?.postal_code || ''
                                    }`
                                    : factory?.geoData?.formattedAddress?.toUpperCase?.()}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    ) : null;
}

export default Factory;
