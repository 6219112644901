import React, { useEffect, useRef, useState } from 'react';
import useActions from 'Hooks/useActions';
import useFilters from 'Hooks/useFilters';
import useWindowDimensions from 'Hooks/useWindowDimensions';

import factoryTierToName from 'Store/Constants/factoryTiers';
import dataTimestamp from 'Store/Constants/dataTimestamp';
import factoryTiers from 'Store/Constants/factoryTiers';

import { Motion, spring } from 'react-motion';

import XLSX from 'xlsx';
import { jsPDF } from 'jspdf';

function ExportView() {
    const { height, width } = useWindowDimensions();
    const {
        exportViewData = {},
        factoriesToRender = {},
        factoriesToRenderExportRead = [],
    } = useFilters();

    const actions = useActions();

    const uploadRef = useRef(null);
    const downloadRef = useRef(null);
    const exportFormatRef = useRef('');
    const [fileDownloadUrl, setFileDownloadUrl] = useState('');

    const filtersString = `${exportViewData?.countryName ? exportViewData.countryName : ''
        } ${exportViewData?.countryName && exportViewData?.filters ? ', ' : ''
        } ${exportViewData?.filters} ${!exportViewData?.countryName && !exportViewData?.filters
            ? '(none)'
            : ''
        }`;

    function handleExport(exportFormat) {
        switch (exportFormat) {
            case 'PDF': {
                return function (e) {
                    e.preventDefault();

                    exportFormatRef.current = 'imap_export.pdf';

                    const pdfWidth = 4000;
                    const pdfHeight = 3000;

                    const pdfColumns = [
                        { name: 'Factory Type', keyProp: 'factory_tier' },
                        { name: 'Product Type Type', keyProp: 'product_type' },
                        { name: 'Nike, Inc. Brand(s)', keyProp: 'brand' },
                        { name: 'Events', keyProp: 'special_events' },
                        { name: 'Supplier Group', keyProp: 'supplier_name' },
                        { name: 'Total Workers', keyProp: 'workers' },
                        { name: 'Line Workers', keyProp: 'workers_line' },
                        { name: '% Female Workers', keyProp: 'workers_female' },
                        { name: '% Migrant Workers', keyProp: 'workers_migrant' },
                        { name: 'Subcons', keyProp: 'subcons' },
                    ];

                    const doc = new jsPDF({
                        orientation: 'landscape',
                        unit: 'px',
                        format: [pdfWidth, pdfHeight],
                    });
                    doc.setFont('Helvetica', 'Bold', 'Bold');
                    doc.setFontSize(77);
                    doc.text(
                        `Nike Global Manufacturing Data Export - Filters applied: ${filtersString}${filtersString === '(none)'
                            ? ''
                            : '      Data As Of ' + dataTimestamp?.dataAsOf
                        }`,
                        pdfWidth / 2,
                        100,
                        {
                            align: 'center'
                        },
                    );

                    let pageNumber = 1;
                    let pageLimitCounter = 0;
                    const pageLimit = 7;
                    for (
                        let i = 0;
                        i < factoriesToRenderExportRead.length;
                        i += 1
                    ) {
                        const factoryToRender = factoriesToRenderExportRead[i];

                        if (pageLimitCounter === pageLimit) {
                            pageLimitCounter = 1;
                            doc.addPage({
                                orientation: 'landscape',
                                unit: 'px',
                                format: [pdfWidth, pdfHeight],
                            });
                        } else {
                            pageLimitCounter += 1;
                        }

                        const rowX = 300;
                        const rowY = 47 + pageLimitCounter * 367;

                        doc.setFont('Helvetica', 'Bold', 'Bold');
                        doc.setFontSize(47);
                        doc.text(factoryToRender.name, rowX, rowY - 94, {
                            align: 'left'
                        });

                        doc.setFont('Helvetica', 'Bold', 'Bold');
                        doc.setFontSize(37);
                        doc.text('Country/Region: ', rowX, rowY - 47, {
                            align: 'left'
                        });
                        doc.text('Region: ', 1200, rowY - 47, {
                            align: 'left'
                        });

                        doc.setFont('Helvetica', '', '');
                        doc.text(
                            factoryToRender?.['country/region'],
                            rowX + 300,
                            rowY - 47,
                            {
                                align: 'left'
                            }
                        );

                        doc.text(String(factoryToRender?.['region']), 1500, rowY - 47, {
                            align: 'left'
                        });

                        function nullFallback(propToCheck) {
                            return propToCheck === null ? '' : propToCheck;
                        }

                        const factoryAddressFull = `${nullFallback(
                            factoryToRender?.address || '',
                        )} ${nullFallback(
                            factoryToRender?.city || '',
                        )} ${nullFallback(
                            factoryToRender?.state || '',
                        )} ${nullFallback(factoryToRender?.postal_code || '')}`;
                        doc.text(factoryAddressFull, 300, rowY, {
                            align: 'left'
                        });

                        for (let j = 0; j < pdfColumns.length; j += 1) {
                            const col = pdfColumns[j];
                            const colX = rowX + j * 300;
                            const colY = rowY + 47;

                            doc.setFont('Helvetica', 'Bold', 'Bold');
                            doc.setFontSize(37);
                            doc.text(col.name, colX, colY, {
                                align: 'left'
                            });

                            let colData = factoryToRender[col.keyProp] || '';
                            if (col.name === 'Factory Type') {
                                colData =
                                    factoryTiers?.[factoryToRender?.[col?.keyProp]];
                            }

                            doc.setFont('Helvetica', '', '');
                            doc.setFontSize(37);
                            doc.text(String(colData), colX, colY + 47, {
                                align: 'left'
                            });
                        }
                    }

                    doc.save(exportFormatRef.current);
                };
            }
            case 'EXCEL': {
                return function (e) {
                    e.preventDefault();
                    exportFormatRef.current = 'imap_export.xls';

                    const aoo = [];
                    const columns = [
                        { name: 'Factory Name', keyProp: 'name' },
                        { name: 'Factory Type', keyProp: 'factory_tier' },
                        { name: 'Product Type Type', keyProp: 'product_type' },
                        { name: 'Nike, Inc. Brand(s)', keyProp: 'brand' },
                        { name: 'Events', keyProp: 'special_events' },
                        { name: 'Supplier Group', keyProp: 'supplier_name' },
                        { name: 'Address', keyProp: 'address' },
                        { name: 'City', keyProp: 'city' },
                        { name: 'State', keyProp: 'state' },
                        { name: 'Postal Code', keyProp: 'postal_code' },
                        { name: 'Country / Region', keyProp: 'country/region' },
                        { name: 'Region', keyProp: 'region' },
                        { name: 'Total Workers', keyProp: 'workers' },
                        { name: 'Line Workers', keyProp: 'workers_line' },
                        { name: '% Female Workers', keyProp: 'workers_female' },
                        { name: '% Migrant Workers', keyProp: 'workers_migrant' },
                        /*  { name: 'Contact Name', keyProp: 'contact_name' },
                          { name: 'Contact Phone', keyProp: 'contact_phone' },
                          { name: 'Contact Fax', keyProp: 'contact_fax' },
                          { name: 'Contact Email', keyProp: 'contact_email' },
                          { name: 'Subcons', keyProp: 'subcons' }, */
                    ];
                    for (
                        let i = 0;
                        i < factoriesToRenderExportRead.length;
                        ++i
                    ) {
                        const factoryToExport = factoriesToRenderExportRead[i];
                        const newFactoryToExport = {};

                        for (let j = 0; j < columns.length; j += 1) {
                            const col = columns[j];
                            if (col) {
                                if (col.name === 'Factory Type') {
                                    newFactoryToExport[col?.name] =
                                        factoryTiers?.[factoryToExport?.[col?.keyProp]];
                                } else {
                                    newFactoryToExport[col?.name] =
                                        factoryToExport?.[col?.keyProp];
                                }
                            }
                        }
                        aoo.push(newFactoryToExport);
                    }

                    const headerLine = `Data Export - Filters applied: ${filtersString}${filtersString === '(none)'
                        ? ''
                        : ' Data As Of ' + dataTimestamp?.dataAsOf
                        }`;

                    var ws = XLSX.utils.json_to_sheet([
                        { A: headerLine }
                    ], { skipHeader: true });

                    /* Write data starting at A2 */

                    XLSX.utils.sheet_add_json(ws, aoo, { skipHeader: false, origin: "A2" });

                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(
                        wb,
                        ws,
                        'Nike Global Manufacturing',
                    );
                    const wbData = XLSX.writeFile(wb, exportFormatRef.current);

                    console.log({ wbData });
                };
            }
            case 'JSON': {
                return function (e) {
                    e.preventDefault();
                    exportFormatRef.current = `imap_export.${exportFormat.toLowerCase()}`;
                    const newJsonExport = JSON.stringify(
                        {
                            [`Data As Of ${dataTimestamp?.dataAsOf}`]: factoriesToRenderExportRead,
                        },
                        null,
                        4,
                    );
                    const blob = new Blob([newJsonExport]);
                    setFileDownloadUrl(URL.createObjectURL(blob));
                };
            }
            default: {
                return function (e) {
                    e.preventDefault();
                    exportFormatRef.current = `imap_export.${exportFormat.toLowerCase()}`;
                    console.log({ exportFormat });
                };
            }
        }
    }

    useEffect(() => {
        if (fileDownloadUrl) {
            downloadRef.current.click();
        }
    }, [fileDownloadUrl]);

    return (
        <Motion
            defaultStyle={{ y: height, isOpen: 0 }}
            style={{
                isOpen: spring(exportViewData?.isOpen ? 1 : 0),
                y: spring(!exportViewData?.isOpen ? height - 47 : 47),
            }}
        >
            {(value) => {
                const shouldRenderExportView =
                    value.isOpen === 0 && !exportViewData.isOpen;

                return shouldRenderExportView ? null : (
                    <section
                        className={`export-view ${exportViewData.isOpen ? 'open' : ''
                            }`}
                        style={{ height: height - 47, top: value.y }}
                    >
                        <div className="inner">
                            <span
                                className="close-export"
                                onClick={() => {
                                    actions?.TOGGLE_EXPORT_VIEW?.();
                                }}
                            />
                            <style
                                type="text/css"
                                dangerouslySetInnerHTML={{
                                    __html: '\n\tbody {background:#eee;}\n',
                                }}
                            />
                            <div id="export">
                                <div id="export-header">
                                    {' '}
                                    {/*border bottom black line */}
                                    <div id="date">
                                        Data as of {dataTimestamp?.dataAsOf}
                                    </div>
                                    <h2>
                                        Manufacturing Disclosure /{' '}
                                        {Object.keys(factoriesToRender).length}{' '}
                                        <span className="sub">Locations Listed</span> /{' '}
                                        <span className="sub">
                                            Filters: {filtersString}
                                        </span>
                                    </h2>
                                </div>
                                <div id="export-footer">
                                    <p>Export as </p>
                                    <div id="export-buttons">
                                        <a
                                            className="export pdf"
                                            href="/maps/export_pdf?"
                                            onClick={handleExport('PDF')}
                                        >
                                            PDF
                                        </a>
                                        <a
                                            className="export xls"
                                            href="/maps/export_xls?"
                                            onClick={handleExport('EXCEL')}
                                        >
                                            EXCEL
                                        </a>{' '}
                                        <a
                                            className="export json"
                                            href="/maps/export_json?"
                                            onClick={handleExport('JSON')}
                                        >
                                            JSON
                                        </a>
                                        <a
                                            className="hidden"
                                            download={exportFormatRef.current}
                                            href={fileDownloadUrl}
                                            ref={downloadRef}
                                        >
                                            download it
                                        </a>
                                    </div>
                                    <p>
                                        NOTE: At this time, contact information is only
                                        available for factories that manufacture licensed
                                        collegiate products.
                                    </p>
                                </div>
                                <div
                                    id="export-table"
                                    style={{ width: '100%', height: height - 245 }}
                                >
                                    <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        id="export-data"
                                    >
                                        <tbody>
                                            <tr>
                                                <th>Factory Name</th>
                                                <th>Factory type</th>
                                                <th>Product Type</th>
                                                <th>Nike, Inc. Brand(s)</th>
                                                <th>Events</th>
                                                <th>Supplier Group</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Postal Code</th>
                                                <th>Country/Region</th>
                                                <th>Region</th>
                                                <th>Total Workers</th>
                                                <th>Line Workers</th>
                                                <th>% Female Workers</th>
                                                <th>% Migrant Workers</th>
                                                {/* <th>Contact Name</th>
                                                <th>Contact Phone</th>
                                                <th>Contact Fax</th>
                                                <th>Contact Email</th>
                                                <th>Subcons</th>  */}
                                            </tr>
                                            {Object.values(factoriesToRender).map(
                                                (factory) => {
                                                    return (
                                                        <tr key={factory?.contractor_id}>
                                                            <td>{factory?.name}</td>
                                                            <td>
                                                                {
                                                                    factoryTierToName?.[
                                                                    factory?.factory_tier
                                                                    ]
                                                                }
                                                            </td>
                                                            <td>{factory?.product_type}</td>
                                                            <td>{factory?.brand}</td>
                                                            <td>{factory?.special_events}</td>
                                                            <td>{factory?.supplier_name}</td>
                                                            <td>{factory?.address}</td>
                                                            <td>{factory?.city}</td>
                                                            <td>{factory?.state}</td>
                                                            <td>{factory?.postal_code}</td>
                                                            <td>{factory?.['country/region']}</td>
                                                            <td>{factory?.region}</td>
                                                            <td>{factory?.workers}</td>
                                                            <td>{factory?.workers_line}</td>
                                                            <td>{factory?.workers_female}</td>
                                                            <td>{factory?.workers_migrant}</td>
                                                            {/* <td>{factory?.contact_name}</td>
                                                            <td>{factory?.contact_phone}</td>
                                                            <td>{factory?.contact_fax}</td>
                                                            <td>{factory?.contact_email}</td>
                                                            <td>{factory?.subcons}</td> */}
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                );
            }}
        </Motion>
    );
}

export default ExportView;
