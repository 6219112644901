import { take, put } from 'redux-saga/effects';

import ActionTypes from 'Store/ActionTypes';
import ActionStatus from 'Store/ActionStatus';
import envConfig from 'Assets/config/env';

import newFactoryData from 'Store/Constants/newData';

function* appLaunchWatcher() {
    while (true) {
        yield take(ActionTypes.APP_LAUNCH);

        const countryToData = {};

        try {
            for (let d = 0; d < newFactoryData.length; d += 1) {
                const factory = newFactoryData[d];

                const factoryCountryCode = factory?.geoData?.A3;

                if (factory?.geoData && factoryCountryCode) {
                    countryToData[factoryCountryCode] = {
                        ...(countryToData?.[factoryCountryCode] || {}),
                        data: [
                            ...(countryToData?.[factoryCountryCode]?.data || []),
                            factory,
                        ],
                    };
                }
            }
        } catch (e) {
            console.log('something went wrong', e);
        }

        yield put({
            type: ActionStatus.SUCCESS,
            origin: ActionTypes.APP_LAUNCH,
            data: {
                envConfig,
                countryToData,
            },
        });
    }
}

export default appLaunchWatcher;
