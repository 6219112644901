import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logo from 'Assets/images/map-nikeink-logo.png';
import useActions from 'Hooks/useActions';

function Header() {
    const actions = useActions();

    return (
        <header
            className="map-ribbon top"
            role="banner"
            style={{
                background: `url(${logo}) 0 1px no-repeat #fff`,
                top: 0,
                boxShadow: '0 0 5px #ccc',
                height: 47,
                left: 0,
                overflow: 'hidden',
                position: 'absolute',
                width: '100%',
                zIndex: 13,
            }}
        >
            <a
                href="http://nikeinc.com"
                title="Back to NIKE, INC.com"
                className="branding-logo"
            ></a>
            <a
                href="#"
                className="open-legend right"
                onClick={() => {
                    actions.TOGGLE_ABOUT();
                }}
            >
        About
            </a>
            <a href="/" className="open-home right">
        Home
            </a>
        </header>
    );
}

export default React.memo(Header);
