const initialState = {
    GOOGLE: {
        //API_KEY: 'AIzaSyBuSVhxTTXrleXUWpFqC2HmBbL2xIqpbqc',
        API_KEY:process.env.REACT_APP_GOOGLE_API_KEY,
        styles: [
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    { visibility: 'on' },
                    { saturation: -100 },
                    { lightness: 70 },
                ],
            },
            {
                featureType: 'landscape',
                stylers: [
                    { visibility: 'on' },
                    { lightness: 10 },
                    { saturation: -100 },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'administrative.province',
                elementType: 'geometry',
                stylers: [{ visibility: 'on' }],
            },
            {
                featureType: 'administrative.province',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'administrative.country',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'administrative.locality',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'administrative.neighborhood',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road.local',
                elementType: 'geometry',
                stylers: [
                    { saturation: -100 },
                    { lightness: -72 },
                    { visibility: 'on' },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'labels',
                stylers: [
                    { visibility: 'on' },
                    { invert_lightness: true },
                    { gamma: 9.99 },
                    { saturation: -100 },
                    { lightness: 10 },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [
                    { visibility: 'on' },
                    { saturation: -100 },
                    { lightness: -72 },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels',
                stylers: [
                    { visibility: 'on' },
                    { saturation: -100 },
                    { invert_lightness: true },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels',
                stylers: [{ visibility: 'simplified' }],
            },
            {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.government',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.medical',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.place_of_worship',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.school',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                    { visibility: 'off' },
                    { saturation: -100 },
                    { lightness: -66 },
                ],
            },
        ],
    },
};

function ConfigReducer(state = initialState, action = {}) {
    console.log(state);
    switch (action?.type) {

    default: {
        return state;
    }
    }
}

export default ConfigReducer;
