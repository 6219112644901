import { useSelector } from 'react-redux';

function Tags() {
    const exportView = useSelector((state) => state?.exportView);

    function buildTagRender(tagType, tagTitle, tagClass) {
        const tagArray = exportView?.[tagType] || [];
        const tagArrayLength = tagArray.length;
        return tagArrayLength ? (
            <div className={tagClass}>
                <span>{tagTitle}</span>
                <ul>
                    {tagArray?.map((tag, tagIndex) => {
                        const isLast = !(tagIndex !== tagArrayLength - 1);
                        const isOne = !(tagArrayLength > 1);

                        return (
                            <li key={tag}>
                                {tag}
                                {!isLast && !isOne ? ',' : ''}
                            </li>
                        );
                    })}
                </ul>
            </div>
        ) : null;
    }

    return exportView?.hasFilters ? (
        <div className="faceted-selection on">
            <h3 className="accessibility">You Selected</h3>
            {buildTagRender('BRANDS', 'Brand(s):', 'brand-filters-list')}
            {buildTagRender(
                'PRODUCT TYPES',
                'Product type(s):',
                'product-filters-list',
            )}
            {buildTagRender(
                'COLLEGIATE',
                'Event(s):',
                'event-filters-list',
            )}
        </div>
    ) : (
        <div className="accessibility faceted-selection">
            <h3 className="accessibility">You Selected</h3>
            <div className="brand-filters-list empty-list" />
            <div className="product-filters-list empty-list" />
            <div className="event-filters-list empty-list" />
        </div>
    );
}

export default Tags;
