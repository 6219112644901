export function leaveBreadcrumb(...args) {
    const groupId = 'LEAVE_BREADCRUMB';
    console.group(groupId);
    args.forEach((arg) => {
        console.warn(arg);
    });
    console.groupEnd(groupId);
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}
