import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useWindowDimensions from 'Hooks/useWindowDimensions';
import useActions from 'Hooks/useActions';

function Intro() {
    const intro = useSelector((state) => state?.intro);
    const { title, isOpen, copy } = intro || {};

    const actions = useActions();

    const [introOpen, setIntroOpen] = useState(isOpen);

    const actionFired = useRef(false);

    useEffect(() => {
        if (!introOpen) {
            if (!actionFired.current) {
                actionFired.current = true;
                actions.CLOSE_INTRO();
            }
        }
    }, [introOpen, actions]);

    return introOpen ? (
        <aside className="intro">
            <h1>{title}</h1>
            {copy?.map((paragraphCopy, copyIndex) => {
                return (
                    <p
                        key={copyIndex}
                        dangerouslySetInnerHTML={{ __html: paragraphCopy }}
                    />
                );
            })}

            <span
                onClick={() => {
                    setIntroOpen(false);
                }}
                className="close-intro"
            ></span>
        </aside>
    ) : null;
}

export default React.memo(Intro);
