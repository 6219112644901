import useActions from 'Hooks/useActions';

function FilterData(props = {}) {
    const actions = useActions();

    const data = props?.data?.() || {};
    return (
        <a
            href="#"
            className="export-button"
            onClick={() => {
                actions?.TOGGLE_EXPORT_VIEW?.(data);
            }}
        >
      Export This Data
        </a>
    );
}

export default FilterData;
