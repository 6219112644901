import useFilters from 'Hooks/useFilters';

function ChooseFactory({
    data = [],
    visible,
    onClosePicker,
    onPick,
}) {
    function onPicking(id) {
        return () => {
            onPick(id);
        };
    }

    const { factoriesToRender = {} } = useFilters();

    return visible ? (
        <li className="scope-selection country current-scope">
            <fieldset>
                <a
                    href="#"
                    onClick={onClosePicker}
                    className="close-selection"
                >
          Close x
                </a>
                <h2>Select a Factory</h2>
                <legend className="accessibility">
          Select a country that you would like to filter by:
                </legend>
                <ol
                    style={{
                        height: 638,
                        maxHeight: 638,
                        overflow: 'hidden',
                        overflowY: 'auto',
                        padding: 0,
                        wth: 358,
                    }}
                    className="jspScrollable"
                    tabIndex={0}
                >
                    <div
                        className="jspContainer"
                        style={{ width: 358, height: 638, overflowY: 'auto' }}
                    >
                        <div
                            className="jspPane"
                            style={{ padding: 0, top: 0, width: 345 }}
                        >
                           
                            {data ?.sort(function (a, b) {
                                console.log({ a, b });
                                var textA = a?.name.toUpperCase();
                                var textB = b?.name.toUpperCase();
                                return textA < textB ? -1 : textA > textB ? 1 : 0;
                                })
                            ?.map((factory) => {
                                const id = factory?.contractor_id;
                                if (!factoriesToRender?.[id]) {
                                    return null;
                                }
                                return (
                                    <li key={id} onClick={onPicking(id)}>
                                        <label>
                                            <input
                                                type="radio"
                                                className="accessibility"
                                                name="data[Factory][id]"
                                                defaultValue={64538}
                                            />
                                            {factory?.name || id}
                                        </label>
                                    </li>
                                );
                            })}
                          
                        </div>
                        {/* <div className="jspVerticalBar">
              <div className="jspCap jspCapTop" />
              <div className="jspTrack" style={{ height: 638 }}>
                <div
                  className="jspDrag"
                  style={{ height: 605, top: 0 }}
                >
                  <div className="jspDragTop" />
                  <div className="jspDragBottom" />
                </div>
              </div>
              <div className="jspCap jspCapBottom" />
            </div> */}
                    </div>
                </ol>
            </fieldset>
        </li>
    ) : null;
}

export default ChooseFactory;
