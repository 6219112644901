const actionTypes = {
    TOGGLE_ABOUT: 'TOGGLE_ABOUT',
    CLOSE_INTRO: 'CLOSE_INTRO',
    APP_LAUNCH: 'APP_LAUNCH',
    TOGGLE_EXPORT_VIEW: 'TOGGLE_EXPORT_VIEW',
    TOGGLE_FILTERS_VIEW: 'TOGGLE_FILTERS_VIEW',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    SELECT_COUNTRY: 'SELECT_COUNTRY',
    SELECT_FACTORY: 'SELECT_FACTORY',
};

export default actionTypes;
