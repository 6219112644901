import React from 'react';

import dataTimestamp from 'Store/Constants/dataTimestamp';

function Footer() {
    return (
        <footer className="map-ribbon bottom" role="contentinfo">
            <div className="inner">
                <span className="date-disclaimer">
          Data as of {dataTimestamp?.dataAsOf}
                </span>
            </div>
        </footer>
    );
}

export default React.memo(Footer);
