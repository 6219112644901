import useActions from 'Hooks/useActions';
import useMetrics from 'Hooks/useMetrics';

import FilterData from 'Components/Micro/FilterData';
import ExportData from 'Components/Micro/ExportData';
import ShowPercentageLabel from 'Components/Micro/ShowPercentageLabel';
import CountryProfile from 'Components/Micro/CountryProfile';

import Tags from './Filters/Tags';

import { Motion, spring } from 'react-motion';

function Country({
    name,
    country,
    data: factories = [],
    visible,
    onChooseCountryRegion,
}) {
    const {
        countryMetrics = {},
        currentCountryMetrics = {},
        tier1 = {},
        materials = {},
    } = useMetrics({
        factories,
        region: 'COUNTRY',
    });

    const {
        averageAge,
        workersLabel,
        dataLength,
        dataLengthPercentage,
        workersPercentage,
        workersFemalePercentageLabel,
        allMigrantWorkersPercentageLabel,
    } = countryMetrics;

    if (!factories?.length) {
        return null;
    }

    const productTypesMetrics = {
        EQUIPMENT: tier1?.Equipment || 0,
        APPAREL: tier1?.Apparel || 0,
        FOOTWEAR: tier1?.Footwear || 0,

    };

    const productTypesTotal =
        productTypesMetrics.Equipment ? productTypesMetrics.Equipment : 0 +
            productTypesMetrics.Apparel ? productTypesMetrics.Apparel : 0 +
                productTypesMetrics.Footwear ? productTypesMetrics.Footwear : 0

    /*     productTypesMetrics.Equipment +
         productTypesMetrics.Apparel +
         productTypesMetrics.Footwear;
 */
    /*   console.log("Print APPAREL:")
       console.log(tier1)
       console.log(spring(tier1?.Apparel))
       console.log(JSON.stringify(tier1))
       console.log(tier1.Apparel)
       console.log(productTypesMetrics.Apparel)
       console.log({ tier1, countryMetrics, currentCountryMetrics });
   */
    return !visible || !name || !country ? null : (
        <li
            className="current-scope country-scope jspScrollable"
            style={{
                height: 638,
                maxHeight: 653,
                overflow: 'hidden',
                padding: 0,
                width: 350,
            }}
            tabIndex={0}
        >
            <div
                className="jspContainer"
                style={{ width: 350, height: 638, overflowY: 'auto' }}
            >
                <div
                    className="jspPane"
                    style={{ padding: '0px 15px 15px', top: 0, width: 307 }}
                >
                    <fieldset>
                        <legend className="accessibility">
                            Here you can observe entire country numbers, or further
                            choose individule factories.
                        </legend>
                        <a
                            href="#"
                            className="step-back"
                            id="remove-country-pins"
                            onClick={onChooseCountryRegion}
                        >
                            Choose Country / Region
                        </a>
                        <h2>{name}</h2>
                        <Tags />
                        <FilterData region="COUNTRY" />
                        <Motion
                            defaultStyle={{
                                tier1CountriesCount: 0,
                                tier1CountryPercentage: 0,
                                tier1FactoriesCount: 0,
                                tier1FactoriesToGlobalPercentage: 0,
                                tier1Workers: 0,
                                tier1WorkersToGlobalPercentage: 0,
                                materialsCountriesCount: 0,
                                materialsCountryPercentage: 0,
                                materialsFactoriesCount: 0,
                                materialsFactoryPercentage: 0,
                            }}
                            style={{
                                tier1CountriesCount: spring(tier1?.countryLength),
                                tier1CountryPercentage: spring(
                                    tier1?.countryPercentage,
                                ),
                                tier1FactoriesCount: spring(tier1?.factories?.length),
                                tier1FactoriesToGlobalPercentage: spring(
                                    tier1?.factoriesToGlobalPercentage,
                                ),
                                tier1Workers: spring(tier1?.workers),
                                tier1WorkersToGlobalPercentage: spring(
                                    tier1?.workersToGlobalPercentage,
                                ),
                                materialsCountriesCount: spring(
                                    materials?.countryLength,
                                ),
                                materialsCountryPercentage: spring(
                                    materials?.countryPercentage,
                                ),
                                materialsFactoriesCount: spring(
                                    materials?.factories?.length,
                                ),
                                materialsFactoryPercentage: spring(
                                    materials?.factoriesPercentage,
                                ),
                            }}
                        >
                            {(value) => {
                                return (
                                    <ul className="scope-details">
                                        <h2 align="left">FINISHED GOODS</h2>
                                        <li className="factories">
                                            <strong>
                                                {value.tier1FactoriesCount.toFixed(0) ||
                                                    currentCountryMetrics?.dataLength ||
                                                    dataLength}
                                            </strong>{' '}
                                            Factories{' '}
                                            <div
                                                id="country-factories-bar"
                                                className="bar-chart"
                                            >
                                                <span
                                                    style={{
                                                        width: `${value.tier1FactoriesToGlobalPercentage ||
                                                            currentCountryMetrics?.dataLengthPercentage ||
                                                            dataLengthPercentage
                                                            }%`,
                                                    }}
                                                />
                                            </div>{' '}
                                            <span className="percents">
                                                {value.tier1FactoriesToGlobalPercentage.toFixed(
                                                    0,
                                                ) ||
                                                    currentCountryMetrics?.dataLengthPercentage ||
                                                    dataLengthPercentage}
                                                %
                                            </span>
                                        </li>
                                        <li className="workers">
                                            <strong>
                                                {value.tier1Workers
                                                    ?.toFixed(0)
                                                    ?.toString?.()
                                                    ?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ',') ||
                                                    currentCountryMetrics?.workersLabel ||
                                                    workersLabel}
                                            </strong>{' '}
                                            Workers{' '}
                                            <div
                                                id="country-workers-bar"
                                                className="bar-chart"
                                            >
                                                <span
                                                    style={{
                                                        width: `${value.tier1WorkersToGlobalPercentage}%`,
                                                    }}
                                                />
                                            </div>{' '}
                                            <span className="percents">
                                                {value.tier1WorkersToGlobalPercentage?.toFixed(
                                                    0,
                                                ) ||
                                                    currentCountryMetrics?.workersToGlobalPercentageLabel}
                                                %
                                            </span>
                                        </li>
                                        <div className="divider"></div>
                                        <br />
                                    </ul>
                                );
                            }}
                        </Motion>
                        <div className="section">
                            <CountryProfile
                                {...{
                                    currentCountryMetrics,
                                    productTypesTotal,
                                    productTypesMetrics,
                                }}
                            />
                            <ul className="worker-details">
                                <li className="female">
                                    Female{' '}
                                    <strong>
                                        <ShowPercentageLabel
                                            data={
                                                currentCountryMetrics?.workersFemalePercentageLabel ||
                                                workersFemalePercentageLabel
                                            }
                                        />
                                    </strong>
                                </li>
                                <li className="migrant">
                                    Migrant{' '}
                                    <strong>
                                        <ShowPercentageLabel
                                            data={
                                                currentCountryMetrics?.allMigrantWorkersPercentageLabel ||
                                                allMigrantWorkersPercentageLabel
                                            }
                                        />
                                    </strong>
                                </li>
                                <li className="avg-age">
                                    Average Age{' '}
                                    <strong>
                                        {currentCountryMetrics?.averageAge || averageAge}
                                    </strong>
                                </li>
                            </ul>
                        </div>
                        <div className="scope-details">
                            <div className="tier2BTL">
                                <br />
                                <h2>MATERIALS</h2>
                                <li className="factories">
                                    <strong>{materials?.factoriesLength}</strong>{' '}
                                    Facilities{' '}
                                    <div
                                        id="country-factories-bar1"
                                        className="bar-chart"
                                    >
                                        <span
                                            style={{
                                                width: `${materials?.factoriesPercentage || 0
                                                    }%`,
                                            }}
                                        />
                                    </div>{' '}
                                    <span className="percents">
                                        {materials?.factoriesPercentage}%
                                    </span>
                                </li>
                            </div>
                        </div>
                        <div className="notice">
                            <br />
                            Line chart % based on total NIKE Inc. factories and
                            workers for finished goods production and on total
                            strategic vendors for NIKE brand materials production
                        </div>
                        <ExportData
                            data={() => {
                                const countryName = country?.properties?.ADMIN;
                                return {
                                    countryId: country?.id,
                                    countryName,
                                    filters: `${countryName}`,
                                };
                            }}
                        />
                    </fieldset>
                </div>
                {/* <div className="jspVerticalBar">
          <div className="jspCap jspCapTop" />
          <div className="jspTrack" style={{ height: 638 }}>
            <div className="jspDrag" style={{ height: 634 }}>
              <div className="jspDragTop" />
              <div className="jspDragBottom" />
            </div>
          </div>
          <div className="jspCap jspCapBottom" />
        </div> */}
            </div>
        </li>
    );
}

export default Country;
