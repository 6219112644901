import actionTypes from 'Store/ActionTypes';

const { CLOSE_INTRO } = actionTypes;

const initialState = {
    isOpen: true,
    title: 'Nike Manufacturing Map:',
    copy: [
        'Transparency is fundamental to NIKE, Inc. business and our approach to sustainability.',
        `This site is a tool to learn about the independent factories
    and material suppliers used to manufacture NIKE products -
    including the name and location of each factory and the types
    of products they produce. For finished goods facilities, the
    tool also includes information about the workers at each
    factory.`,
        `More information on our commitment to making product
    responsibly is available at
    <a href="https://sustainability.nike.com/" target="_blank">
      purpose.nike.com
    </a>.`,
    ],
};

function IntroReducer(state = initialState, action = {}) {
    switch (action?.type) {
    case CLOSE_INTRO: {
        return {
            ...state,
            isOpen: false,
        };
    }
    default: {
        return state;
    }
    }
}

export default IntroReducer;
